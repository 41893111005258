import {mapState, mapGetters, mapMutations, mapActions} from "vuex";

export const vm_searchbox = {
    name:'SearchBoxInHeader',
    data(){
      return {
          on_focus:false,
          FOCUS_STYLE_CLASS:'is-focused',
          wrapper_el:null,
      };
    },
    mounted(){
        this.wrapper_el = document.getElementById('js-searchbox');
    },
    computed:{
        ...mapState('searchbox',['keyword','search_summary','old']),
        ...mapGetters('searchboxMenu',{getSearchboxMenuState:'getState'}),
        state_of_searchbox_menu(){
            return this.getSearchboxMenuState;
        },
        keyword_value:{
            get(){
                return this.on_focus ? this.keyword : this.search_summary;
            },
            set(val){
                this.setState({keyword:val});
            }
        },
        is_searchbox_menu_open:{
            get(){
                return this.state_of_searchbox_menu.is_open;
            },
            set(val){
                this.setIsOpen(val);
            }
        }
    },
    directives:{
        'focus':{
            mounted: function (el, binding) {
                let f = function (evt) {
                    if (binding.value(evt, el)) {
                        el.removeEventListener('focus', f, { passive: true });
                    }
                }
                el.addEventListener('focus', f, {passive: true});
            }
        },
        'blur':{
            mounted: function (el, binding) {
                let f = function (evt) {
                    if (binding.value(evt, el)) {
                        el.removeEventListener('blur', f, { passive: true });
                    }
                }
                el.addEventListener('blur', f, {passive: true});
            }
        }
    },
    methods:{
        ...mapMutations('searchbox',['setState']),
        ...mapActions('searchboxMenu',['setIsOpen','setIsMoving']),
        handleFocus(e,el){
            // divタブではないんのでclassの付け替えがVueでできないためベタに変更
            this.wrapper_el.classList.add(this.FOCUS_STYLE_CLASS);
            this.is_searchbox_menu_open = true;
            this.on_focus = true;
        },
        handleBlur(e,el){
            this.on_focus = false;
        },
        closeSearchboxMenu(){
            this.wrapper_el.classList.remove(this.FOCUS_STYLE_CLASS);
            this.is_searchbox_menu_open = false;
            // キーワードを初期値に戻したい
            this.$store.dispatch('searchbox/init', {...this.old});
        },
    }
};
