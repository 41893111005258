import {realmAttributeController} from "@/lib/realmAttributeController";
import _base_form_parts_list from "@/const/base_form_parts_list";
import {isNumber} from "@/lib/isNumber";
import {isString} from "@/lib/isString";
import {isUndefined} from "@/lib/isUndefined";
import {isEmpty} from "@/lib/isEmpty";

/**
 * @typedef FormDataOption
 * @prop {string} label
 * @prop {string|number} value
 */
/**
 * @typedef FormData
 * @prop {string} form_data の name。
 * @prop {string[]} [sub] CSSのサブモジュールの文字列。
 * @prop {string} [type]
 * @prop {FormDataOption[]} checkbox、radio select の選択肢
 */
/**
 * @typedef FormParts
 * @prop {string} ttl_label 見出し文字列
 * @prop {string} name form_parts を区別するname
 * @prop {string[]} sub CSSのサブモジュールの文字列。1つめで使用コンポーネントを判断するので、1つ以上の指定必須
 * @prop {string[]} [is_status] CSSの is-系となる。is-を省いた文字列
 * @prop {FormData|{from:FormData,to:FormData}} [form_data]
 * @prop {string} [kind] リストをフィルタリングする際に使用
 */
/**
 * エラーメッセージのデフォルト値
 * @type {Object.<string,string>}
 */
const def_err_msgs = {
    empty:'入力必須です',
    empty_list:'選択必須です',
    use_only_number:'半角数字で入力してください',
    over_maxlength:'__MAXLENGTH__文字以内で入力してください',
    under_minlength:'__MINLENGTH__文字以上入力してください',
    over_max:'__MAX__以下の数値を入力してください',
    under_min:'__MIN__以上の数値を入力してください',
    min_num_over_max_num:'最大値は最小値より大きい数値を入力してください',
    min_date_over_max_date:'右の入力欄には左の入力欄より未来の日付を入力してください'
}

/**
 * 各form_parts内にぶらさがる form_data をリスト化して返す。（from to があるパターンがある）
 * @param {FormParts} _form_parts
 * @return {form_data[]}
 * @private
 */
const _form_data_list_of = (_form_parts) => {
    return _form_parts.form_data.name
        ? [_form_parts.form_data]
        : Object.keys(_form_parts.form_data).reduce((ret, key) => {
            ret.push(_form_parts.form_data[key]);
            return ret;
        }, []);
}

/**
 * @typedef StateObject
 * @prop {boolean|null} is_total_market トータルマーケットか否か
 * @prop {FormParts[]} form_parts_list form_parts の配列
 * @prop {Object.<string,string|number>} values form_data の name を キーに value を持つオブジェクト
 */
/**
 * store moduleを返す
 * @return {{namespaced:Boolean, state:StateObject, getters:Object,actions:Object,mutations:Object}}
 */
const makeParamsModule = () => {

    const state = {
        is_total_market:null,
        form_parts_list:[],
        values:{},
    };

    const getters = {
        getValues:(state) => {
            return state.values;
        },
        /**
         * 指定された name の valueを返す
         * @param state
         * @return {function(name):String|Number|Array}
         */
        getValueOf:(state) => (name) => {
            return state.values[name];
        },

        /**
         * form_parts_list をかえす
         * @param state
         * @return {function(kind:string?):Array}
         */
        getFormPartsList:(state) => (kind) => {
            return state.form_parts_list.filter(form_parts => {
                return !kind || form_parts.kind === kind;
            });
        },

        /**
         * 渡された name の form_data を持つ form_parts を返す
         * ※form_parts の name じゃなくて form_data の nameを探しにいく
         * @param state
         * @return {function(name:string):Object|null}
         */
        getFormPartsByName:(state) => (name) =>{
            return state.form_parts_list.reduce( (ret, form_parts) => {
                _form_data_list_of(form_parts).forEach(form_data => {
                    if( form_data.name === name ){
                        ret = form_parts;
                    }
                })
                return ret;
            },null);
        },

        /**
         * 渡されたname の form_data を返す
         * @param state
         * @return {function(name:string):Object|null}
         */
        getFormDataByName:(state) => (name) => {
            return state.form_parts_list.reduce( (ret, form_parts) => {
                if(form_parts.form_data.name === name) {
                    ret = form_parts.form_data;
                }
                return ret;
            },null);
        },

        /**
         * @typedef {Object} PayloadOfGetValuesObj
         * @property {string[]} [tgt_keys] - 指定があればこのキーのものだけを対象
         * @property {string[]} [except_keys] - 指定があればこのキーははずす
         */
        /**
         * 渡された条件にあう key value のオブジェクトを返す
         * ※ value がない（空、もしくは空配列）のものは返さない！！
         * @param state
         * @return { function(PayloadOfGetValuesObj):Object }
         */
        getValuesObj:(state) => ({tgt_keys = [],except_keys=[]}) => {
            const ret = {};
            for(let key in state.values){
                if(state.values[key] !== null && state.values[key] !== undefined && state.values[key] !== '' && (!Array.isArray(state.values[key]) || state.values[key].length) && (!tgt_keys.length || tgt_keys.includes(key)) && !except_keys.includes(key)){
                    ret[key] = state.values[key];
                }
            }
            return ret;
        },

        /**
         * @typedef {Object} PayloadOfGetValuesObjForApiParams
         * @property {string[]} [tgt_keys] - 指定があればこのキーのものだけを対象
         * @property {string[]} [except_keys] - 指定があればこのキーははずす
         * @property {Object} [overwrite_params] - このキーのものは上書きor追加。tgt_keys, except_keys の条件は見ずに上乗せされる
         */
        /**
         * 渡された条件にあう key value のオブジェクトで生成したWebAPI用のパラメータオブジェクトを返す
         * ※値がない項目は渡されない
         * ※attributes 系が渡せる形に加工される
         * @param state
         * @param getters
         * @return { function(PayloadOfGetValuesObjForApiParams):Object }
         */
        getValuesObjForApiParams:(state,  getters) => ({tgt_keys = [], except_keys=[], overwrite_params={}}) => {
            const _params_obj = Object.assign({}, getters.getValuesObj({tgt_keys,except_keys}),overwrite_params);
            let _realm_attribute_idx = 0; // realm_attributeはパラメータ名に連番の idx をもつ必要があるので管理
            const _realm_id_in_values = _params_obj.realm_id; // realm_attribute で 該当する category_id のものしかおくれないので
            return Object.keys(_params_obj).reduce((ret, key) => {
                if(state.is_total_market || key !== 'service_id'){
                    if(Array.isArray(_params_obj[key])){
                        _params_obj[key].forEach(_params_value => {
                            ret[key] = _params_value;
                        });
                    }else if(_params_obj[key] !== null && _params_obj[key] !== '' &&  _params_obj[key] !== undefined){
                        if(key.match(/^attributes/)){
                            const _realm_attribute = realmAttributeController.getObjByName(key);
                            if(_realm_id_in_values &&  _realm_id_in_values+'' === _realm_attribute.realm_id+''){
                                if(isUndefined(ret['attributes'])){
                                    ret['attributes'] = [];
                                }
                                ret['attributes'].push({
                                    trait_type:_realm_attribute.trait_type,
                                    value_type:_realm_attribute.value_type,
                                    [_realm_attribute.value_key_name]:_params_obj[key]
                                })
                                _realm_attribute_idx++;
                            }
                        }else{
                            ret[key] = _params_obj[key];
                        }
                    }
                }
                return ret;
            }, {});
        },

        /**
         * @typedef {Object} PayloadOfGetUrlParamsStr
         * @property {string[]} [tgt_keys] - 指定があればこのキーのものだけを対象
         * @property {string[]} [except_keys] - 指定があればこのキーははずす
         * @property {Object} [overwrite_params] - このキーのものは上書きor追加。tgt_keys, except_keys の条件は見ずに上乗せされる
         */
        /**
         * 渡された条件にあう key value のオブジェクトで生成したURLパラメータ文字列を返す。最初の ?or& はついてない
         * @param state
         * @param getters
         * @return { function(PayloadOfGetUrlParamsStr):Object }
         */
        getUrlParamsStr:(state,  getters) => ({tgt_keys = [],except_keys=[],overwrite_params={}}) => {
            const _params_obj = Object.assign({},getters.getValuesObj({tgt_keys,except_keys}),overwrite_params);
            let _realm_attribute_idx = 0; // realm_attributeはパラメータ名に連番の idx をもつ必要があるので管理
            const _realm_id_in_values = _params_obj.realm_id; // realm_attribute で 該当する category_id のものしかおくれないので
            let _realmAttributes = [];   // 数値のattributeでminとmaxがあるかを調べたいのでgetObjByNameしたobjを保持
            return Object.keys(_params_obj).reduce((ret, key) => {
                if(state.is_total_market || key !== 'service_id'){
                    if(Array.isArray(_params_obj[key])){
                        _params_obj[key].forEach(_params_value => {
                            ret.push(key+'='+encodeURIComponent(_params_value));
                        });
                    }else if(_params_obj[key] != null && _params_obj[key] !== ''){
                        if(key.match(/^attributes/)){
                            /* attributes は name を分割して複数submitする */
                            const _realm_attribute = realmAttributeController.getObjByName(key);
                            if (_realm_id_in_values && _realm_id_in_values + '' === _realm_attribute.realm_id + '') {
                                if (_realmAttributes.findIndex(item => _realm_attribute.realm_id === item.realm_id && _realm_attribute.trait_type === item.trait_type && _realm_attribute.value_type === item.value_type) > -1) {
                                    // 同じ項目があった場合はvalue_key_nameと値だけ（おそらくint_min_valueとint_max_value前後している）
                                    ret.push('attributes[' + (_realm_attribute_idx - 1) + '][' + _realm_attribute.value_key_name + ']' + '=' + encodeURIComponent(_params_obj[key]));
                                } else {
                                    ret.push('attributes[' + _realm_attribute_idx + '][trait_type]' + '=' + encodeURIComponent(_realm_attribute.trait_type));
                                    ret.push('attributes[' + _realm_attribute_idx + '][value_type]' + '=' + encodeURIComponent(_realm_attribute.value_type));
                                    ret.push('attributes[' + _realm_attribute_idx + '][' + _realm_attribute.value_key_name + ']' + '=' + encodeURIComponent(_params_obj[key]));
                                    _realm_attribute_idx++;
                                }
                            }
                            _realmAttributes.push(_realm_attribute);
                        }else{
                            ret.push(key+'='+encodeURIComponent(_params_obj[key]));
                        }
                    }
                }
                return ret;
            }, []).join('&');
        },

        /**
         * this.state.values の中から submit するべきものだけに絞ったObjectを返す
         * （具体的には values の name から該当の form_data.disabled:true となっているものをはずして返す）
         * （value の値が空でも含まれる）
         * @param state
         * @param getters
         * @return { Object }
         */
        getValuesToBeSubmit(state, getters){
            return Object.keys(state.values).reduce((ret,name) => {
                const form_data = getters.getFormDataByName(name);
                if(!form_data || !form_data.disabled){
                    ret[name] = getters.getValueOf(name);
                }
                return ret;
            }, {});
        },

        /**
         * @typedef {Object} PayloadOfGetErrObj
         * @property {Object} [values] - チェック対象。未指定の場合は state.values が対象となる
         * @property {string} [mode] - draft がきたら、未入力は許す。
         * @property {Array} [form_parts_list] - 渡されたら state.form_parts_list ではなくてこちらを優先
         * @property {Array} [form_parts_list_of_realm_attributes=[]] - realm_attributes もチェック対象の場合
         */
        /**
         * 現在のエラーを form_parts の name をキーに、エラーメッセージを配列でぶら下げたオブジェクトで返す
         * エラーが1つもないときは null を返す
         * @param state
         * @param getters
         * @return { function(PayloadOfGetErrObj):Object|null }
         */
        getErrObj:(state, getters) => ({values,mode, form_parts_list, form_parts_list_of_realm_attributes =[]}) =>{
            let has_err = false;
            const ret = {};
            const tgt_values = values || state.values;
            const tgt_form_parts = form_parts_list || state.form_parts_list;

            tgt_form_parts.concat(form_parts_list_of_realm_attributes).forEach(form_parts => {
                // form_parts単位でエラーメッセージを格納
                if(!ret[form_parts.name]){
                    ret[form_parts.name] = [];
                }
                // form_data の内容から、チェック対象のvaluesをチェック
                _form_data_list_of(form_parts).forEach(form_data => {
                    // disabled な項目は無視
                    if(form_data.disabled){
                        return;
                    }

                    // チェック対象の値
                    const tgt_value = tgt_values[form_data.name];

                    // 未入力チェック
                    if(form_data.is_required && mode !== 'draft'){
                        // 未入力チェック
                        if(tgt_value === '' || Array.isArray(tgt_value) && !tgt_value.length){
                            has_err = true;
                            if(form_data.list === undefined){
                                if(form_data.type && form_data.type === 'number'){
                                    ret[form_parts.name].push(form_data.err_msgs.use_only_number || def_err_msgs.use_only_number);
                                }else{
                                    ret[form_parts.name].push(form_data.err_msgs.empty || def_err_msgs.empty);
                                }
                            } else{
                                ret[form_parts.name].push(form_data.err_msgs.empty_list || def_err_msgs.empty_list);
                            }

                        }
                    }

                    // maxlength チェック
                    if(form_data.maxlength){
                        if(!isUndefined(tgt_value) && tgt_value !== '' && !Array.isArray(tgt_value) && tgt_value.length > form_data.maxlength){
                            has_err = true;
                            ret[form_parts.name].push( (form_data.err_msgs.over_maxlength || def_err_msgs.over_maxlength).replace(/__MAXLENGTH__/g, form_data.maxlength));
                        }
                    }

                    // minlength チェック
                    if(form_data.minlength){
                        if(!isUndefined(tgt_value) && tgt_value !== '' && !Array.isArray(tgt_value) && tgt_value.length < form_data.minlength){
                            has_err = true;
                            ret[form_parts.name].push( (form_data.err_msgs.under_minlength || def_err_msgs.under_minlength).replace(/__MINLENGTH__/g, form_data.minlength));
                        }
                    }
                    // max チェック
                    if(!isUndefined(form_data.max)) {
                        if(!isUndefined(tgt_value) && tgt_value !== '' && !Array.isArray(tgt_value) && tgt_value*1 > form_data.max){
                            has_err = true;
                            ret[form_parts.name].push( (form_data.err_msgs.over_max || def_err_msgs.over_max).replace(/__MAX__/g, form_data.max));
                        }
                    }

                    // min チェック
                    if(!isUndefined(form_data.min)) {
                        if(!isUndefined(tgt_value) && tgt_value !== '' && !Array.isArray(tgt_value) && tgt_value*1 < form_data.min){
                            has_err = true;
                            ret[form_parts.name].push( (form_data.err_msgs.under_min || def_err_msgs.under_min).replace(/__MIN__/g, form_data.min));
                        }
                    }

                    // 個別のバリデーション
                    if(form_data.validation){
                        const validation_result = form_data.validation(tgt_value, {mode, state:{values:tgt_values, form_parts_list:tgt_form_parts}});
                        if(validation_result.has_err){
                            has_err = true;
                            ret[form_parts.name].push(validation_result.err_msg)
                        }

                    }
                });

                // from to で to の値が from より小さくないかチェック。
                // form_parts.sub の 1つめが price_range, min_max, date_range が from to パターン
                // date_range はパーツとして作ったが使っている箇所がない
                if(!isUndefined(form_parts.sub)
                    && Array.isArray(form_parts.sub)
                    && (form_parts.sub[0] === 'price_range' || form_parts.sub[0] === 'min_max' || form_parts.sub[0] === 'date_range')
                    && form_parts.form_data
                    && form_parts.form_data.from
                    && form_parts.form_data.from.name
                    && form_parts.form_data.to
                    && form_parts.form_data.to.name){
                    const from_val = tgt_values[form_parts.form_data.from.name];
                    const to_val = tgt_values[form_parts.form_data.to.name];
                    if(!isUndefined(from_val) && !isUndefined(to_val) && from_val !== '' && to_val !== ''){
                        // from to 両方が入力されていればチェック
                        // フォームから受け取る値は文字列になってしまうので *1 をしてからisNumberをチェックする
                        if(isNumber(from_val*1) && isNumber(to_val*1)){
                            if(to_val*1 < from_val*1){
                                has_err = true;
                                ret[form_parts.name].push((form_parts.form_data.from.err_msgs.min_num_over_max_num || def_err_msgs.min_num_over_max_num))
                            }
                        }else if(isString(from_val) && isString(to_val) && from_val && to_val){
                            // date が入っている想定。フォーマットがそろっているからそのまま比較でOK
                            if(to_val < from_val){
                                has_err = true;
                                ret[form_parts.name].push((form_parts.form_data.from.err_msgs.min_date_over_max_date || def_err_msgs.min_date_over_max_date))
                            }
                        }
                    }
                }
            });
            return has_err ? ret : null;
        },
        /**
         * タイトル一覧を取得
         * */
        getServices(state, getters){
            const tgt_form_parts = state.form_parts_list.find(form_parts=> form_parts.name==='game');
            if (tgt_form_parts) {
                return tgt_form_parts.form_data.list.filter(item => item.value !== '');
            }
            return [];
        }
    };

    const actions = {
        /**
         * @typedef option
         * @property {string} value
         * @property {string} label
         */
        /**
         * 初期化処理
         * @param state
         * @param commit
         * @param dispatch
         * @param {string} [type] _base_form_parts_list のキー名を渡される。 type, base_form_parts_list のいずれの指定必須
         * @param {FormParts[]} [base_form_parts_list] type, base_form_parts_list のいずれの指定必須
         * @param {Boolean} is_total_market トータルマーケットか否か
         * @param {Object.<string,string|number|string[]|number[]>} values form_data.name キーに value を持つ
         * @param {Object.<string,Object.<string,string|number>>} attributes_from_svr 整形してからvaluesにアサインしたいattributes
         * @param {Object.<string,option[]>} [options_from_svr] form_data の list 系の情報を form_data.nameをキーに
         * @param {Object.<string,Object.<string,string|number>>} form_data_from_svr form_data にぶらさげたいキーとvalueのオブジェクトを form_data.name をキーに
         */
        init({state, commit, dispatch},{type,base_form_parts_list,is_total_market,values, attributes_from_svr ,options_from_svr = {}, form_data_from_svr={}}) {
            if((!type && !base_form_parts_list) || isUndefined(is_total_market) || !values){
                console.error('ParamsModuleに必要な情報が不足しています。')
            }
            // トータルマーケットかどうかの情報をセット
            state.is_total_market = is_total_market;

            /**
             * attributesを整形する
             * @param attributes {Array}
             * @param realm_id {String}
             * @returns {Object}
             * */
            const formattedAttributes = (({attributes=[], realm_id=''}) => {
                return attributes.reduce((ret, attribute) => {
                    const setAttribute = (_value_key_name) =>{
                        ret[`attributes-${realm_id}-` + attribute.trait_type + '-' + attribute.value_type + '-' + _value_key_name] = attribute[_value_key_name];
                    };
                    if (!isUndefined(attribute.int_value)) {
                        setAttribute('int_value');
                    } else if (!isUndefined(attribute.int_min_value) || !isUndefined(attribute.int_max_value)) {
                        if (!isUndefined(attribute.int_min_value)) {
                            setAttribute('int_min_value');
                        }
                        if (!isUndefined(attribute.int_max_value)) {
                            setAttribute('int_max_value');
                        }
                    } else {
                        setAttribute('string_value');
                    }
                    return ret;
                }, {});
            })({attributes:attributes_from_svr, realm_id:values.realm_id});

            // values 初期値をセット
            commit('setValues',!isEmpty(formattedAttributes) ? Object.assign(values, formattedAttributes) : values);

            // ベースのform_parts_listをセットする
            commit('setFormPartsList', type ? _base_form_parts_list[type] : base_form_parts_list);

            // default値
            commit('setDefaultValueIfNotSet');

            // options_from_svr があればセットする
            for(let name in options_from_svr){
                commit('setListOfFormData',({
                    name,
                    list: options_from_svr[name]
                }));
            }

            // form_data に付け足しがあればセットする
            for(let name in form_data_from_svr){
                for(let property_name in form_data_from_svr[name]){
                    commit('setPropertyOfFormData',{
                        name,
                        property_name,
                        value:form_data_from_svr[name][property_name]
                    });
                }
            }
        },
        clear({state, commit, dispatch},{values}){
            if(!values){
                console.error('clearするのに必要な情報が不足しています。');
            }
            // values 初期値をセット
            if (state.is_total_market) {
                commit('setValues', values);
            } else {
                for (let key in values) {
                    let tmp_values = {};
                    if (key !== 'service_id') {
                        tmp_values[key] = values[key];
                    }
                    commit('setValues', tmp_values);
                }
            }
        },
        setFormProperties({state, commit, dispatch}, {form_data = []}) {
            form_data.forEach(form_data_item => {
                commit('setPropertyOfFormData', {
                    name: form_data_item.name,
                    property_name: form_data_item.property_name,
                    value: form_data_item.value
                });
            });
        },
        updateFormParts({state, commit, dispatch}, {form_data = {}}) {
            if (!Object.keys(form_data).length) return;
            commit('setListOfFormData', {
                name: form_data.name,
                list: form_data.list,
                is_replace: form_data.is_replace ?? false
            });
        },
    };
    const mutations = {
        /**
         * 渡された {name:value} を state.values にセット
         * @param state
         * @param values
         */
        setValues(state, values){
            for(let key in values){
                // 該当のkeyがない or 配列ではない
                if(isUndefined(state.values[key]) || !Array.isArray(state.values[key])){
                    // console.log('なかったから追加⇒', key);
                    state.values[key] = values[key];
                }else{
                    // 配列なら全削除のあと追加
                    state.values[key].splice(0);
                    if(Array.isArray(values[key])){
                        values[key].forEach( add_value =>{
                            state.values[key].push(add_value);
                        })
                    }else{
                        console.log('入れ物が配列なのに配列でvalueがこないケースってある？');
                        state.values[key].push(values[key]);
                    }
                }
            }
        },
        /**
         * 渡された form_parts_list を state.form_parts_list にセット
         * @param state
         * @param {Array} form_parts_list
         */
        setFormPartsList(state,form_parts_list = []){
            if(!Array.isArray(form_parts_list)){
                console.error('form_parts_listが配列になっていません');
                return;
            }
            form_parts_list.forEach( form_parts => {
                // サービスマーケットの場合 game 選択はない
                if (!state.is_total_market && form_parts.name === 'game') {
                    return;
                }
                // form_parts.sub の指定がなければエラー
                if (isUndefined(form_parts.sub) || !Array.isArray(form_parts.sub) || !form_parts.sub.length) {
                    console.error('form_parts.sub[] は 1つ以上の指定が必要です⇒', form_parts, 'form_parts.sub を使用しない場合は ["not_use"]を指定してください')
                    return;
                }
                _form_data_list_of(form_parts).forEach(form_data => {
                    // TODO: ここで form_data の型をそろえる
                    form_data.disabled = form_data.disabled || false;
                    // form_data に err_msgsのキーがなければ空オブジェクトをぶら下げる
                    form_data.err_msgs = form_data.err_msgs || {};
                });

                // form_data の name 重複チェック
                const is_duplicate = _form_data_list_of(form_parts).map(form_data => form_data.name).reduce( (ret,tgt_name) => {
                    if(state.form_parts_list.some(form_parts=>{
                        return form_parts.form_data.name
                            ? form_parts.form_data.name === tgt_name
                            : (form_parts.form_data.from && form_parts.form_data.from.name === tgt_name || form_parts.form_data.to && form_parts.form_data.to.name === tgt_name)
                    })){
                        console.error('form_data の nameが重複しています⇒', form_parts);
                        ret = true;
                    }
                    return ret;
                },false);

                if(!is_duplicate) {
                    state.form_parts_list.push(form_parts);
                }
            });
        },
        /**
         * デフォルト値が設定されていて、valueがないものに、デフォルト値をセット
         * @param state
         */
        setDefaultValueIfNotSet(state){
            state.form_parts_list.forEach(form_parts => {
                _form_data_list_of(form_parts).forEach(form_data => {
                    if (!isUndefined(form_data.default_value) && (isUndefined(state.values[form_data.name]) || state.values[form_data.name]==='' || state.values[form_data.name] === [])) {
                        state.values[form_data.name] = form_data.default_value;
                    }
                })
            })
        },
        /**
         * 指定された name の form_data の list に 渡された list をセットする
         * @param state
         * @param {String} name 追加対象の form_data の name
         * @param {Array} list 追加するリスト
         * @param {Boolean} [is_replace=false] 全とっかえするかどうか？デフォルトは追加
         */
        setListOfFormData(state,{name, list, is_replace = false}){
            state.form_parts_list.forEach( form_parts => {
                _form_data_list_of(form_parts).forEach(form_data => {
                    if( form_data.name === name ){
                        // 全とっかえの場合は、いったん配列を空にする
                        if(is_replace){
                            form_data.list.splice(0);
                        }
                        if (!isUndefined(form_data.sort_index) && form_data.sort_index.length) {
                            // ソートの指定があったらvalueをみてリストをつくる
                            form_data.sort_index.forEach(sort_value => {
                                let list_item = list.find(item => item.value === sort_value);
                                if (list_item) {
                                    form_data.list.push(list_item);
                                }
                            });
                        } else if(list && list.length){
                            list.forEach(list_item => {
                                form_data.list.push(list_item);
                            });
                        }
                    }
                });
            });
        },
        /**
         * 指定された name の form_data の プロパティ をセットする
         * @param {String} name form_data の name
         * @param {String} property_name プロパティ名
         * @param {String|Number} value 追加する値
         */
        setPropertyOfFormData(state,{name,property_name,value}){
            state.form_parts_list.forEach( form_parts => {
                _form_data_list_of(form_parts).forEach(form_data => {
                    if( form_data.name === name ){
                        form_data[property_name] = value;
                    }
                })
            });
        },
        /**
         * サービス一覧にセットする
         * @param {Number} id - サービスID
         * @param {String} name - サービス名
         * **/
        setServiceData(state, {id,name}){
            state.services.push({id,name});
        }

    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    }
}

export { makeParamsModule as default};
