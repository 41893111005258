import api from "@/api";
import {isUndefined} from "@/lib/isUndefined";

const makeRealmConditionModule = () => {
    const state = {
        service_id: null,
        realm_parent_ids: [], // 親のキーを階層順に管理する
        realm_options_by_parent_id: {}, // 親のキーに対してのoptionリスト群
        realm_data:{}, // カテゴリデータを保持する
        realm_attributes_by_id:{}, // カテゴリのIDに対してrealm_attributesを保持する
    }
    const getters = {
        /**
         * 渡された realm_id のデータを返す
         * @param state
         * @return {function(realm_id:string|number):Object}
         */
        getRealmDataOf:(state) => (realm_id) => {
            return state.realm_data[realm_id];
        },

        /**
         * 現在の realm id を返す
         * realm_parent_ids が2件以上なら、最後のrealm_parent_idを、
         * realm_parent_ids が 0or1件なら、''を返す
         * @param state
         * @return {String}
         */
        getCurrentRealmId(state){
            return state.realm_parent_ids.length > 1 ? state.realm_parent_ids[state.realm_parent_ids.length-1] : '';
        },
    }
    const actions = {
        init({state, getters, commit, dispatch},{service_id,realm_id,realm_data_from_svr}) {
            console.log(service_id,realm_id,realm_data_from_svr);

            if(!service_id) return;
            dispatch('setServiceIdAsRootRealm',service_id);

            // console.log('realm_data_from_svr',realm_data_from_svr);
            if (realm_id) {
                // カテゴリ情報を受け取っていれば（改めて取りに行かないで済むように先に）セット
                if (realm_data_from_svr && !getters.getRealmDataOf(realm_data_from_svr.id) && !isUndefined(realm_data_from_svr.id)) {
                    state.realm_data[realm_data_from_svr.id] = realm_data_from_svr;
                    state.realm_attributes_by_id[realm_data_from_svr.id] = realm_data_from_svr.realm_attributes;
                }
                // 現在のカテゴリをセット
                dispatch('updateRealmParentIds', {parent_id: realm_id});
            }
        },
        /**
         * 渡された service_id をルートカテゴリとしてセット
         * 現在保持している service_id から変更がなければ何も処理をしない
         * 現在保持している service_id から変更があった場合は、下層のカテゴリ情報をリセットする
         * @param state
         * @param commit
         * @param dispatch
         * @param {string} service_id
         */
        setServiceIdAsRootRealm({state, commit, dispatch},service_id){
            // console.log('setServiceIdAsRootRealm',service_id);
            if(state.service_id === service_id){
                return;
            }
            state.service_id = service_id;
            state.realm_parent_ids.splice(0);// 必ずリセット

            // service_id が解除されたらここで処理終了
            if(!service_id){
                return;
            }

            // カテゴリIDとキーが重複しないようにサービスIDはキーにsをつける
            state.realm_parent_ids.push('s' + service_id)

            // すでに realm_options を保持していれば改めて取りに行かない
            if(state.realm_options_by_parent_id['s'+service_id]){
                return;
            }

            api.getFilter({
                service_id,
                callback:res=>{
                    dispatch('setRealmOptionsAndRealmAttributesById',{id:'s'+res.data.service.id,options:res.data.realm_options});
                }
            });
        },

        /**
         * 渡された id の options と realm_attributes を必要な箇所にセット
         * realm_options_by_parent_id に、 id をキーにして、options を整形して持たせる
         * options 中身（child_realm情報）option をrealm_data に realm_id をキーに持たせる
         * options 中身（child_realm情報）option にぶら下がっている  realm_attributes を realm_attributes_by_id に realm_id をキーに持たせる
         * @param state
         * @param {String|Number} id realm_id もしくは service_id。parent_id の場合はsが頭について渡ってくる
         * @param {Array} options
         */
        setRealmOptionsAndRealmAttributesById({state},{id,options}){
            // console.log('setRealmOptionsAndRealmAttributesById',id,options);
            state.realm_options_by_parent_id[id] = options.map( option => { return { id:option.value.id,name:option.value.name}});
            options.forEach(option => {
                state.realm_data[option.value.id] = option;

                // realm_attributes もセット
                // option.value.id が realm_id
                state.realm_attributes_by_id[option.value.id] = option.realm_attributes;
                // option.realm_attributes[] の realm_id は、親のカテゴリのものを引き継いでいる場合、親のカテゴリIDとなるので注意

            });
        },

        /**
         * 渡されたindexの位置の realm_parent_id を更新する
         * @param {Number} [index] 未指定の場合は次に追加
         * @param {String|Number} parent_id
         */
        updateRealmParentIds({state, commit, dispatch},{index,parent_id}){
            if (isUndefined(parent_id)) {
                console.error('updateRealmParentIds で parent_id が渡されていません', parent_id);
            }
            // index が未指定だったら次の階層を指定したとする
            if (isUndefined(index)) {
                index = state.realm_parent_ids.length;
            }
            if (state.realm_parent_ids.length === index) {
                // 次の階層が指定された
                commit('addParentId', {parent_id});
            } else if (state.realm_parent_ids.length > index) {
                // 現在選択済みの階層が選択されたので指定された index以降のparent_id をクリアして渡されたものをセットする
                state.realm_parent_ids.splice(index);
                commit('addParentId', {parent_id});
            }
        },
    }
    const mutations = {
        /**
         * realm_parent_idsにparent_idを追加
         * */
        addParentId(state, payload) {
            if (!state.realm_parent_ids.includes(payload.parent_id) && payload.parent_id !== '') {
                state.realm_parent_ids.push(payload.parent_id);
            }
        }
    }
    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    }
}


export { makeRealmConditionModule as default};
