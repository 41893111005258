import axios from 'axios'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(
    (res) => {
        // 削除されたユーザーの場合、エラー画面へ遷移
        if (res.data && res.data.is_user_deleted) {
            window.location.href = res.data.user_deleted_url;
            return;
        }
        return res;
    }
);

export default axios;