export const decodeSpecialChars = (text='') => {
    if (!text) return text;
    return text.replace(/(&amp;|&#039;|&quot;|&lt;|&gt;)/g, (match) => {
        return {
            '&amp;': '&',
            '&#039;': '\'',
            '&quot;': '"',
            '&lt;': '<',
            '&gt;': '>'
        }[match];
    });
};
