import {getStrParamsReplaced} from '@/lib/getStrParamsReplaced'
import axios from '@/lib/axios'

class ApiBase {
    constructor({use_cache = false, api_url_info,api_request_type='post' ,params=[],send_param_names=[],key_names=[]}) {
        this.use_cache = use_cache;
        this.api_url = api_url_info;
        this.api_request_type = api_request_type;
        this.params = params;
        this.send_param_names = send_param_names;
        this.key_names = key_names;
        this.cache = {};
        this.stack = {};
    }

    _getCache(key){
        return this.cache[key] || null;
    }

    _setCache({key,value}){
        // console.log('_setCache',key,value);
        this.cache[key] = value;
    }

    _getStack(key){
        return this.stack[key] || [];
    }

    _setStack({key,fn}){
        if(!this.stack[key]){
            this.stack[key] =  [];
        }
        this.stack[key].push(fn);
    }

    _resetStack(key){
        this.stack[key] && Array.isArray(this.stack[key]) && this.stack[key].splice(0);
    }


    /**
     * API_URL を返す
     * this.api_url_info がセットされてない場合は空文字を返す
     * @param {Object.<string,string|number>}params
     * @return {string}
     * @private
     */
    _getApiUrl(params){
        let ret = '';
        if(this.api_url_info !== void 0){
            if(this.api_url_info.replace_str === void 0){
                ret = this.api_url_info.base_str;
            }else{
                ret = getStrParamsReplaced({base_str:this.api_url_info.base_str,replace_str:this.api_url_info.replace_str,params})
            }
        }
        return ret;
    }

    /**
     * api_url をセットする
     * @param api_url_info
     */
    setApiUrl(api_url_info){
        if(!api_url_info || !api_url_info.base_str){
            console.error('setApiUrl で必要な情報が不足しています。')
        }
        this.api_url_info = api_url_info;
    }

    getAndCallback(params){
        const api_url = this._getApiUrl(params);
        // console.log('getAndCallback', api_url);
        if(!api_url){
            console.error('APIのURLが登録されていません', this);
            return;
        }

        // パラメータチェック
        this.params.forEach(param => {
            if(params[param.name] === void 0){
                console.log('パラメータ不足');
            }
        })

        const key = this.key_names.map( key_name => params[key_name]).join('_');

        if(this.use_cache){
            const cache = this._getCache(key);
            if(cache === 'pending'){
                this._setStack({key,fn:params.callback});
                return;
            }else if(cache){
                params.callback && params.callback(cache);
                return;
            }else{
                this._setCache({key,value:'pending'});
            }
        }

        axios[this.api_request_type](api_url,{...this.send_param_names.reduce( (ret,send_param_name) => {
                ret[send_param_name] = params[send_param_name];
                return ret;
            },{})}).then((res) => {
            if(this.use_cache) {
                this._setCache({key, value: res});
                this._getStack(key).forEach(stack_callback => {
                    stack_callback(res);
                });
                this._resetStack(key);
            }
            params.callback(res);
        });
    }

}

export { ApiBase as default};
