import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";

export const ModalAjaxError = {
    name: "ModalAjaxError",
    mixins: [modalDefaultMixin],
    emits: ['close'],
    template: `
    <div :class="type+'-inner'">
        <p :class="type+'-msg'" v-html="msg"></p>
        <div :class="type+'-btn'"><p class="button button-cancel"><a href="#" @click.prevent="close">閉じる</a></p></div>
    </div>
    `,
    props:{
        store_props:{
            type:Object,
            required:true
        }
    },
    computed:{
        msg(){
            const errors = this.store_props.errors;
            const messages = this.store_props.messages || {default: 'エラーが発生しました。', communication: '通信エラーが発生しました。時間をおいて再度お試しください。'};
            if (this.store_props.status === 200 && errors) {
                if (errors.case_code && errors.case_message) {
                    return '[' + errors.case_code + ']' + '&nbsp;' + errors.case_message;
                }
                // ケースコードがない場合がある
                if (errors.case_message) {
                    return errors.case_message;
                }
            }
            // ajaxのエラーは基本的に「通信エラーが発生しました。時間をおいて再度お試しください。」でOK
            return messages.communication;
        }
    },
};
