export const ItemExternalLink = {
    name: "ItemExternalLink",
    template:`
    <div class="item_param">
        <p class="item_param-detail"><a :href="external_url" target="_blank" rel="nofollow noopener">{{label}}</a></p>
    </div>
    `,
    props:{
        label:{
            type:String,
            default: 'パラメータ詳細'
        },
        external_url:{
            type:String,
            default:''
        }
    }
};
