/**
 * ハッシュ関連の処理
 * @return {object.<function,function,function>}
 *               setEvent onHashChange時に発動するイベントを登録する,
 *               setEventByHash  onHashChange時にハッシュと連動して発動するイベントを登録する,
 */
export const hashChangeController = (()=>{
    let hashChangeEvents = [];
    let hashChangeEventsByHash = {};
    window.addEventListener('hashchange', _runHashChangeEvents, false);

    /**
     * onHashChange時に発動するイベントを登録する
     * @param {object<fn,parent,first>} obj
     */
    function setEvent(obj){
        if(!obj || !obj.fn){return;}
        let hash = _getHash();
        obj.parent = obj.parent||window;
        hashChangeEvents.push({fn:obj.fn,parent:obj.parent});
        // セットされたタイミングで処理
        if(obj.first){
            if(!isNaN(obj.first)){
                setTimeout(function(){
                    obj.fn.call(obj.parent,hash);
                },obj.first);
            }else{
                obj.fn.call(obj.parent,hash);
            }
        }
        return;
    }

    /**
     * onHashChange時にそのときのハッシュと連動して発動するイベントを登録する
     * @param {array.object<fn,parent,hash,first>} obj_ary
     */
    function setEventByHash(obj_ary){
        if(!obj_ary || !obj_ary.length) return;
        let hash = _getHash();
        obj_ary.forEach(function(obj){
            if(!obj.fn || !obj.hash){return;}
            obj.parent = obj.parent||window;
            hashChangeEventsByHash[obj.hash] = {fn:obj.fn,parent:obj.parent};
            if(hash == obj.hash){
                obj.fn.call(obj.parent,hash);
            }
        });
        return;
    }

    /**
     * 登録されたイベントを発動
     * @private
     */
    function _runHashChangeEvents(){
        let hash = _getHash();
        hashChangeEvents.forEach(function(event_obj){
            event_obj.fn.call(event_obj.parent,hash);
        });
        if(hashChangeEventsByHash[hash]){
            hashChangeEventsByHash[hash].fn.call(hashChangeEventsByHash[hash].parent,hash);
        }
        return;
    }

    /**
     * 現在のhashを受け取る
     * @returns {string}
     * @private
     */
    function _getHash(){
        let ret_hash = '';
        if(location.search){
            let tmp_search = (location.search.replace('?','')).split('=');
            if(tmp_search[0] == '_escaped_fragment_'){
                ret_hash = '!'+tmp_search[1];
            }
        }
        if(!ret_hash){
            ret_hash = location.hash?(location.hash).replace('#',''):'';
        }
        return ret_hash;
    }

    return {
        setEvent:setEvent,
        setEventByHash:setEventByHash
    }
})();
