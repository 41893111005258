const wrapperController = (function(){
    let wrapper_elm,wrapper_inner_elm,wrapper_inner_elm_style;
    // let pre_scroll_top = 0;
    function setWrapperElement(){
        if(!wrapper_inner_elm_style) {
            wrapper_elm = document.getElementById('wrapper');
            wrapper_inner_elm = document.getElementById('wrapper-inner');//backgroundが.wrapperのbeforeで設定されているのでwrapperをdisplay=noneにできない
            wrapper_inner_elm_style = wrapper_inner_elm && wrapper_inner_elm.nodeType && wrapper_inner_elm.nodeType === 1 ? wrapper_inner_elm.style : null;
        }
    }
    return {
        fixed(){
            setWrapperElement();
            if(!wrapper_elm){
                console.log('対象のwrapperがありません');
                return;
            }
            wrapper_elm.classList.add('is-fixed');
            // ユーザー操作無効
            wrapper_elm.inert = true;
            // pre_scroll_top = (document.documentElement.scrollTop || document.body.scrollTop);
            // wrapper_elm_style.position = 'fixed';
            // wrapper_elm_style.top = (-1 * pre_scroll_top) + 'px';
            // scrollTo( 0, 0);
        },
        cancelFixed(){
            setWrapperElement();
            if(!wrapper_elm){
                console.log('対象のwrapperがありません');
                return;
            }
            wrapper_elm.classList.remove('is-fixed');
            // ユーザー操作無効
            wrapper_elm.inert = false;
            // wrapper_elm_style.position = '';
            // wrapper_elm_style.top ='';
            // scrollTo( 0, pre_scroll_top);
        },
        hide(){
            setWrapperElement();
            if(!wrapper_inner_elm_style){
                console.log('対象のwrapperがありません');
                return;
            }
            wrapper_inner_elm_style.display='none';
        },
        show(){
            setWrapperElement();
            if(!wrapper_inner_elm_style){
                console.log('対象のwrapperがありません');
                return;
            }
            wrapper_inner_elm_style.display='';
        }

    };
})();

export { wrapperController as default};
