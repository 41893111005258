/*
* api 下では、ほぼ全ページで使用する ＆ キャッシュとスタック機能を持たせたい
* market_api.search.filter.index
* market_api.search.realm.show
* の2つの WebAPI の処理のみ管理します。
* */
import itemFilter from "./itemFilter";
import itemRealm from "./itemRealm";
import itemParam from "./itemParam";

const getFilter = params => {
    itemFilter.getAndCallback(params)
}
const setFilterApiUrl = params => {
    itemFilter.setApiUrl(params)
}

const getRealm = params => {
    itemRealm.getAndCallback(params)
}

const setRealmApiUrl = params => {
    itemRealm.setApiUrl(params)
}


const getParam = params => {
    itemParam.getAndCallback(params)
}

const setParamsApiUrl = params => {
    itemParam.setApiUrl(params)
}

const index = {
    getFilter,
    getRealm,
    getParam,
    setFilterApiUrl,
    setRealmApiUrl,
    setParamsApiUrl
}

export { index as default, getFilter, getRealm, getParam};
