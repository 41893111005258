import {Loading} from './Loading.js'
import {FormParts} from './FormParts.js'
import {FormSelect} from './FormSelect.js'

export const RealmConditions = {
    name: "RealmConditions",
    template:`
      <transition name="fade">
        <div :class="root_class_name" v-if="is_disp">
          <Loading v-if="is_loading" :is_show="is_loading" sub="min"></Loading>
          <template v-else-if="mode==='form_parts'">
            <FormParts :name="name" :sub="sub" :is_status="is_status" :ttl_label="ttl_label" :form_data="form_data" :values="realm_values" :err_msgs="err_msgs" @update-val="updateVal"></FormParts>
          </template>
          <template v-else>
            <FormSelect :form_data="form_data" :values="realm_values" @update-val="updateVal"></FormSelect>
          </template>
        </div>
      </transition>
    `,
    emits:['update-val'],
    components:{
        Loading,
        FormParts,
        FormSelect
    },
    props: {
        /* realm_parent_ids 内の何番目か */
        index:{
            type:Number,
            required:true
        },
        /* realm_conditions の store の module の名前 */
        realm_store_module_name:{
            type:String,
            required:true
        },
        /* 現在のコンポーネントのrealm_parent_id */
        realm_parent_id:{
            type:[String,Number],
            required:true
        },
        /* 見出し */
        ttl_label:{
            type: String,
            default(){
                return ''
            }
        },
        /* form_parts に渡す状態class名 is- 付きで使われる  */
        add_is_status:{
            type:Array,
            default(){
                return []
            }
        },
        form_parts:{
            type:Object,
            required:true
        },
        values:{
            type: Object,
            required:true
        },
        err_msgs:{
            type: Object
        },
        /* ルートのdivにつく class名 */
        root_class_name:{
            type:String,
            default(){
                return '';
            }
        },
        /* form_parts もしくは form_select を想定。出力する場所にあわせて。*/
        mode:{
            type:String,
            default(){
                return 'form_parts'
            }
        },
        /* 親がカテゴリだったときのoption の 一番上にすべてを追加するか？ */
        has_all_option:{
            type:Boolean,
            default(){
                return false;
            }
        },
    },
    computed:{
        realm_data(){
            return this.$store.state[this.realm_store_module_name].realm_data;
        },
        realm_parent_ids(){
            return this.$store.state[this.realm_store_module_name].realm_parent_ids
        },
        realm_options_by_parent_id(){
            return this.$store.state[this.realm_store_module_name].realm_options_by_parent_id
        },
        /**
         * form_hoge コンポーネント用のname:value のオブジェクト
         * サーバとやりとりするのは最下層のカテゴリIDだけなのでフロント制御用のnameとそれにぶら下がるparent_id のセット
         */
        realm_values(){
            return this.realm_parent_ids.reduce( (ret, parent_id, index, base_list) => {
                ret['realm_'+ index] = base_list[++index] || '';
                return ret;
            },{});
        },
        /**
         * form_hoge コンポーネントで使われる name
         * サーバとやりとりするのは最下層のカテゴリIDだけなのでこの name はフロント制御用
         */
        name(){
            return 'realm_'+this.index;
        },
        /**
         * 表示するかどうか。
         */
        is_disp(){
            return this.index === 0;
        },
        /**
         * 読み込み中かどうか
         * this.is_disp が true なのに、
         * realm_parent_id に options をもっていなければ loading
         */
        is_loading(){
            return this.is_disp && this.realm_options_by_parent_id[this.realm_parent_id] === void 0;
        },
        /**
         * すべての選択肢を表示する条件の場合はすべてのオプションを配列で返す
         * 条件を満たさないときは空配列を返す
         */
        all_option(){
            const ret = [];
            if(this.has_all_option){
                ret.push({
                    label:'指定なし',
                    value: ''
                })
            }
            return ret;
        },
        /**
         * form_hoge コンポーネントで使われる form_data
         * サーバとやりとりするのは最下層のカテゴリIDだけなのでこの name はフロント制御用
         */
        form_data(){
            return {
                err_msgs:{},
                list:[].concat(this.all_option,
                    this.realm_options_by_parent_id[this.realm_parent_id] ? this.realm_options_by_parent_id[this.realm_parent_id].map(option => {
                    return {
                        label:option.name,
                        value:option.id
                    }
                }):[]),
                name:this.name
            };
        },
        sub(){
            return this.form_parts.sub || [];
        },
        is_status(){
            return (this.form_parts.is_status || []).concat(this.add_is_status);
        },
    },
    methods:{
        updateVal({name,val}){
            console.log(name,val);

            // 更新対象の index はこのコンポーネントの次の（子の）階層なので1を足してStoreに対して更新をかける
            const tgt_index = ++(name.split('_')[1]);
            this.$store.dispatch(this.realm_store_module_name+'/updateRealmParentIds',{index:tgt_index,parent_id:val});

            // realm_parent_ids の長さをみて value 更新のための情報を親に渡す。
            const current_realm_id = this.$store.getters[this.realm_store_module_name+'/getCurrentRealmId'];
            this.$emit('update-val',{name:'realm_id',val:current_realm_id});

        },
    },
};
