import {mapGetters,mapState,mapActions} from 'vuex';
import {FormParts} from "./FormParts.js";
import {FormPartsForItemAttributes} from "./FormPartsForItemAttributes.js";
import {FormSelect} from "./FormSelect.js";
import {Loading} from "./Loading.js";
import {RealmConditions} from "./RealmConditions.js";
import {isUndefined} from "../lib/isUndefined.js";
import {realmAttributeController} from "../lib/realmAttributeController.js";
import {decodeSpecialChars} from "../lib/decodeSpecialChars.js";

export const SearchConditions = {
    name: "SearchConditions",
    template:`
    <div class="l-content860">
        <!-- MEMO: カテゴリを表示する準備ができたら表示したい-->
        <template v-if="is_initialized">
            <div class="l-box-primary">
                <div class="l-form" v-if="is_disp.service">
                    <div class="form_select">
                        <div v-for="form_parts_data in form_parts_list_in.service" :key="form_parts_data.name" >
                            <FormParts :name="form_parts_data.name" :sub="form_parts_data.sub" :is_status="form_parts_data.is_status" :ttl_label="form_parts_data.ttl_label" :form_data="form_parts_data.form_data" :values="tmp_values" :err_msgs="err_msgs" @updateVal="updateVal"></FormParts>
                        </div>
                    </div>
                </div>
                <div class="l-form" v-if="is_disp.realm_conditions">
                    <RealmConditions root_class_name="form_select"
                                      v-for="(realm_parent_id, index) in realm_parent_ids"
                                      :key="realm_parent_id" :realm_parent_id="realm_parent_id"
                                      :form_parts="form_parts_of_realm" :values="tmp_values" :index="index"
                                      :ttl_label="index === 0 ? 'カテゴリ':''"
                                      :realm_store_module_name="realm_store_module_name"
                                      :has_all_option=true
                                      @updateVal="updateVal"></RealmConditions>
                </div>
                <template v-for="form_parts_data in form_parts_list_in.other">
                    <div class="l-form" v-if="form_parts_data.name==='keyword'||form_parts_data.name==='price_range'">
                        <FormParts :key="form_parts_data.name" :name="form_parts_data.name" :sub="form_parts_data.sub" :is_status="form_parts_data.is_status" :ttl_label="form_parts_data.ttl_label" :form_data="form_parts_data.form_data" :values="tmp_values" :err_msgs="err_msgs" @updateVal="updateVal"></FormParts>
                    </div>
                </template>
                <template v-if="is_disp.game_item_params && form_parts_list_of_item_params.length">
                    <div class="l-form" v-for="(detail_param,detail_param_idx) in item_attributes" :key="'detail_param-'+detail_param_idx">
                            <dl class="l-form-headline">
                                <dt>詳細条件{{detail_param_idx+1}}</dt>
                                <dd><a href="#" @click.prevent="removeItemAttribute(detail_param_idx)">Clear</a></dd>
                            </dl>
                            <div class="form_select">
                                <select name="attribute" @change="changeItemAttribute($event,detail_param_idx)">
                                    <option value="" :selected="detail_param===''">選択してください</option>
                                    <template v-for="(item_param, idx) in form_parts_list_of_item_params">
                                        <option v-if="detail_param===idx||item_attributes.indexOf(idx)===-1" :key="item_param.name" :value="idx" :selected="detail_param===idx">{{item_param.ttl_label}}</option>
                                    </template>
                                </select>
                            </div>
                        <FormPartsForItemAttributes v-if="detail_param!==''" :key="form_parts_list_of_item_params[detail_param].name" :name="form_parts_list_of_item_params[detail_param].name" :sub="form_parts_list_of_item_params[detail_param].sub" :is_status="form_parts_list_of_item_params[detail_param].is_status" :ttl_label="form_parts_list_of_item_params[detail_param].ttl_label" :form_data="form_parts_list_of_item_params[detail_param].form_data" :values="tmp_values" :err_msgs="err_msgs" @updateVal="updateVal"></FormPartsForItemAttributes>
                    </div>
                    <transition name="fade">
                        <p class="button_add" v-if="!is_item_attributes_max"><a href="#" @click.prevent="addItemAttribute('')">詳細条件を追加</a></p>
                    </transition>
                </template>
            </div>
            <div class="l-box-primary">
                <template v-for="form_parts_data in form_parts_list_in.other">
                    <div class="l-form" v-if="form_parts_data.name!=='keyword' && form_parts_data.name!=='price_range' && !form_parts_data.form_data.disabled">
                        <FormParts :key="form_parts_data.name" :name="form_parts_data.name" :sub="form_parts_data.sub" :is_status="form_parts_data.is_status" :ttl_label="form_parts_data.ttl_label" :form_data="form_parts_data.form_data" :values="tmp_values" :err_msgs="err_msgs" @updateVal="updateVal"></FormParts>
                    </div>
                </template>
            </div>
            <div class="l-two_choices">
                <div class="l-two_choices-back"><p class="button button-cancel"><a href="#" @click.prevent="clearTmpValues">{{close_btn_label}}</a></p></div>
                <div class="l-two_choices-next">
                    <p class="button is-disabled" v-if="has_error_in_form"><a>{{ok_btn_label}}</a></p>
                    <p class="button" v-else><a href="#" @click.prevent="changeSearchConditions">{{ok_btn_label}}</a></p>
                </div>
            </div>
        </template>
        <Loading :is_show="!is_initialized"></Loading>
    </div>
    `,
    components: {
        FormPartsForItemAttributes,
        FormParts,
        FormSelect,
        Loading,
        RealmConditions
    },
    data(){
        return {
            is_initialized:false,
            err_msgs:{},
            tmp_values:{},
            item_attributes:[]
        };
    },
    props: {
        initial_values:{
            type: Object,
            default(){
                return {};
            }
        },
        fixed_values:{
            type: Object,
            default(){
                return {};
            }
        },
        realm_store_module_name:{
            type: String,
            default: 'realmConditionsSearchbox'
        },
    },
    computed:{
        ...mapGetters('urls',['getUrlOf']),
        ...mapGetters('realmConditionsSearchbox',['getRealmDataOf']),
        ...mapState('mainParams',['values','is_total_market']),
        ...mapGetters('mainParams',['getFormPartsList','getErrObj','getUrlParamsStr','getFormPartsByName','getFormDataByName']),
        realm_parent_ids(){
            return this.$store.state[this.realm_store_module_name].realm_parent_ids;
        },
        realm_attributes_by_id(){
            return this.$store.state[this.realm_store_module_name].realm_attributes_by_id;
        },
        form_parts_list_in(){
            return {
                service:this.getFormPartsList('service'),
                other:this.getFormPartsList('other')
            }
        },
        // カテゴリごとのパラメータは動的
        form_parts_list_of_item_params(){
            return this.realm_attributes_by_id[this.tmp_values.realm_id]
                ? this.realm_attributes_by_id[this.tmp_values.realm_id].map( (realm_attribute,idx) => {
                    return realmAttributeController.makeFormParts({realm_id:this.tmp_values.realm_id, realm_attribute,idx});
                })
                : [];
        },
        /* カテゴリのform_parts データを返す */
        form_parts_of_realm(){
            return this.getFormPartsByName('realm_id');
        },
        is_disp(){
            return {
                // サービス選択
                service: this.is_total_market,
                // カテゴリ選択
                realm_conditions: true,
                // 現在のカテゴリにゲームアイテムパラメータがある
                game_item_params: !!this.game_item_params_num,
                // 価格と販売ステータス
                other: true,
            }
        },
        game_item_params_num(){
            return !isUndefined(this.realm_attributes_by_id[this.tmp_values.realm_id]) ? this.realm_attributes_by_id[this.tmp_values.realm_id].length : 0;
        },
        close_btn_label(){
            return 'クリア';
        },
        ok_btn_label(){
            return '検索';
        },
        is_item_attributes_max() {
            return (this.item_attributes.length >= this.game_item_params_num);
        },
        has_error_in_form(){
            return this.checkErrorInForm();
        }
    },
    created(){
        // 編集用のデータを用意
        this.initTmpValues();
        // ルートカテゴリをセット
        this.setServiceIdToStoreRealm();
        // 現在のカテゴリをセット
        this.$store.dispatch(this.realm_store_module_name + '/updateRealmParentIds', {
            index: 1,
            parent_id: this.tmp_values.realm_id
        });
    },
    mounted(){
        console.log('mounted');
        this.$nextTick((e)=> {
            // item_attributesにtmp_valuesのattributesをアサインしたい
            for (let key in this.tmp_values) {
                if (this.isAttributes(key)) {
                    const idx = this.form_parts_list_of_item_params.findIndex(item => item.ttl_label === (realmAttributeController.getObjByName(key)).trait_type);
                    if (idx > -1) {
                        this.addItemAttribute(idx);
                    }
                }
            }
            console.log('is_initialized');
            this.is_initialized = true;
        });
    },
    methods:{
        ...mapActions('modal',['openModalSearchConditions']),
        /**
         * this.tmp_values の初期化
         */
        initTmpValues() {
            let obj = {...this.values};
            for (let key in this.initial_values) {
                const original = !isUndefined(this.fixed_values[key]) ? this.fixed_values[key] : this.initial_values[key];
                if (Array.isArray(original)) {
                    obj[key].splice(0, obj[key].length);
                    obj[key] = [...original];
                } else {
                    obj[key] = original;
                }
            }
            if (obj.keyword !== '') {
                obj.keyword = decodeSpecialChars(obj.keyword);
            }
            this.tmp_values = obj;
        },
        // クリアボタン
        clearTmpValues() {
            const hasValue = (obj,key) => {
                return obj && obj[key] != null && obj[key] !== '';
            };
            const is_fixed_service_id = hasValue(this.fixed_values,'service_id');

            for (let key in this.tmp_values) {
                // サービスマーケットorサービスID固定だったらservice_idは変更しない
                if (key === 'service_id' && (!this.is_total_market || is_fixed_service_id)) continue;

                const form_data = this.getFormDataByName(key);
                this.tmp_values[key] = form_data ? form_data.default_value : '';
                if (hasValue(this.fixed_values,key)) {
                    // 固定値は上書きしたい
                    this.tmp_values[key] = this.fixed_values[key];
                }
                if (key === 'service_id') {
                    // service_idが変わる場合はカテゴリを設定しなおす
                    this.setServiceIdToStoreRealm();
                }
            }
            this.$store.dispatch('mainParams/clear', {
                values: this.tmp_values
            });
            //service_idは変更せずカテゴリ選択をクリアしたい
            if (!this.is_total_market || is_fixed_service_id) {
                this.$store.dispatch(this.realm_store_module_name + '/updateRealmParentIds', {index: 1, parent_id: ''});
            }
            this.$store.dispatch('searchbox/clear');
        },
        /**
         * 子コンポーネントからの更新受け取り口
         * @param {String} name
         * @param {String|Number|Array} val
         */
        updateVal({name,val}){
            this.setTmpValues({[name]:val});
        },
        setTmpValues(obj) {
            for (let key in obj) {
                if (isUndefined(this.tmp_values[key]) || !Array.isArray(this.tmp_values[key])) {
                    // 配列でなければそのまま上書き
                    this.tmp_values[key] = obj[key];
                } else {
                    // 配列なら全削除のあと追加
                    this.tmp_values[key].splice(0);
                    if (Array.isArray(obj[key])) {
                        obj[key].forEach(add_value => {
                            this.tmp_values[key].push(add_value);
                        })
                    } else {
                        console.log('入れ物が配列なのに配列でvalueがこないケースってあるの？');
                        this.tmp_values[key].push(obj[key]);
                    }
                }
                /* service_id に変更があった場合 */
                if (key === 'service_id') {
                    this.setServiceIdToStoreRealm();
                    if (!isUndefined(this.tmp_values.realm_id)) {
                        this.tmp_values.realm_id = '';
                    }
                }
            }
        },
        /**
         * 現在のservice_id を realm_conditions_store に通知
         */
        setServiceIdToStoreRealm(){
            console.log('setServiceIdAsRootRealm');
            this.$store.dispatch(this.realm_store_module_name+'/setServiceIdAsRootRealm',this.tmp_values.service_id)
        },
        /**
         * フォーム内容をチェックして、エラーがあれば エラー内容を err_msgs にセットしtrue を返す
         * エラーがなければ、err_msgs をリセットし、 false を返す
         * @return {boolean}
         */
        checkErrorInForm(){
            console.log('checkForm');
            const form_parts_list = Object.keys(this.is_disp).reduce((ret,key) => {
                if(this.is_disp[key]){
                    if(key === 'service' || key === 'other'){
                        ret = ret.concat(this.form_parts_list_in[key]);
                    }else if(key === 'realm_conditions'){
                        ret.push(this.form_parts_of_realm)
                    }
                }
                return ret;
            },[]);
            const form_parts_list_of_realm_attributes = this.is_disp.game_item_params ? this.form_parts_list_of_item_params : [];
            const err_obj = this.getErrObj({values:this.tmp_values, form_parts_list, form_parts_list_of_realm_attributes});

            if(err_obj){
                for(let form_parts_name in err_obj){
                    this.err_msgs[form_parts_name] = err_obj[form_parts_name];
                }
            }else{
                for(let form_parts_name in this.err_msgs){
                    this.err_msgs[form_parts_name] = [];
                }
            }
            return !!err_obj;
        },
        changeSearchConditions(){
            const overwrite_params = Object.assign({},this.tmp_values);
            console.log(this.getUrlParamsStr({overwrite_params}));
            location.href = this.getUrlOf('SEARCH_ITEMS') + '?' + this.getUrlParamsStr({overwrite_params});
        },
        isAttributes(val){
            return (new RegExp(/^attributes-/)).test(val);
        },
        // 詳細検索条件の追加
        addItemAttribute(val) {
            if(!this.is_item_attributes_max && (val==='' || this.item_attributes.indexOf(val) === -1)) {
                this.item_attributes.push(val ?? '');
            }
        },
        /**
         * tmp_valuesにあるAttributesを初期化
         * @param val{Number|String} keyに含まれる文字列
         * */
        clearAttributeOfTmpValues(val){
            if(this.item_attributes[val]==='') return;
            const form_parts_list_of_item_param = this.form_parts_list_of_item_params[this.item_attributes[val]];
            // 数値でmin,maxがあるタイプ
            if(form_parts_list_of_item_param.form_data.from && form_parts_list_of_item_param.form_data.to){
                ['from','to'].forEach(tgt=>{
                    if(this.tmp_values.hasOwnProperty(form_parts_list_of_item_param.form_data[tgt].name)){
                        this.updateVal({ name: form_parts_list_of_item_param.form_data[tgt].name, val: ''});
                    }
                });
            } else if(this.tmp_values.hasOwnProperty(form_parts_list_of_item_param.form_data.name)){
                this.updateVal({name: form_parts_list_of_item_param.form_data.name, val: ''});
            }

        },
        /**
         * 詳細条件を削除
         * @param idx{Number} item_attributesの該当の値のインデックス
         * */
        removeItemAttribute(idx){
            this.clearAttributeOfTmpValues(idx);
            this.item_attributes.splice(idx,1);
        },
        /**
         * プルダウン選択時にtmp_valuesの該当の値を削除、item_attributesの更新する
         * @param e{Event} $event
         * @param idx{Number} item_attributesの該当の値のインデックス
         * */
        changeItemAttribute(e,idx) {
            this.clearAttributeOfTmpValues(idx);
            let new_item_attributes = this.item_attributes.slice();
            new_item_attributes[idx] = e.target.value!== '' ? Number(e.target.value) : '';
            this.item_attributes = new_item_attributes;
        }
    },
    watch:{
        'tmp_values.realm_id': {
            deep: true,
            handler(new_val, old_val){
                console.log(new_val, old_val);
                if(new_val!==old_val){
                    this.item_attributes.splice(0);
                }
            }
        }
    }
};
