// グローバルのmodalで親vueインスタンスから受け取るものセット
export const modalDefaultMixin = {
    props: {
        type: String,
        vars: Object
    },
    methods: {
        close: function () {
            this.$emit('close');
            return false;
        }
    }
};
