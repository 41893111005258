export const FormSelect= {
    name: "FormSelect",
    emits:['update-val'],
    template:`
        <div class="form_select" :class="classes">
            <select :name="name" v-model="value" :disabled="form_data.disabled">
                <option v-for="option in options" :key="option.value+'_'" :value="option.value">{{option.label}}</option>
            </select>
        </div>
    `,
    props:{
        form_data:{
            type:Object,
            required:true
        },
        values:{
            type:Object,
            required:true
        },
        add_is_status:{
            type:Array,
            default(){
                return [];
            }
        }
    },
    computed:{
        name(){
            return this.form_data.name || '';
        },
        options(){
            return this.form_data.list;
        },
        classes(){
            const sub = this.form_data.sub || [];
            const is_status = this.form_data.is_status || [];
            return sub.map( sub_str => 'form_select-'+sub_str).concat(is_status.map( is_str => 'is-'+is_str),this.add_is_status.map( is_str => 'is-'+is_str));
        },
        value:{
            get(){
                return this.values[this.form_data.name] || '';
            },
            set(val){
                console.log('form_select',{name:this.form_data.name,val});
                this.$emit('update-val', {name:this.form_data.name,val});
            }
        },
    },
};
