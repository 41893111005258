import {createApp,defineAsyncComponent,markRaw} from 'vue/dist/vue.esm-bundler'
window.Vue = {
    createApp,
    defineAsyncComponent,
    markRaw
};

import {formatDistance,add,format} from 'date-fns'
import utcToZonedTime from "date-fns-tz/utcToZonedTime"
import ja from "date-fns/locale/ja";
window.DateFns = {
    format,
    formatDistance,
    add,
    utcToZonedTime,
    locale:{
        ja
    }
};

import axios from '@/lib/axios'/* defaults.headers.common['X-Requested-With'] が設定されたものを使用 */
window.axios = axios;

import {createStore,mapGetters,mapActions,mapState} from 'vuex/dist/vuex.esm-bundler'
import store from '@/store'
window.Vuex = {
    createStore,
    mapGetters,
    mapActions,
    mapState,
    store
};

import api from '@/api'
window.api = api;

import storageController from '@/lib/storageController'
import responsiveController from '@/lib/responsiveController'
import wrapperController from '@/lib/wrapperController'
import {smoothScroll,smoothScrollByHash} from '@/lib/smoothScroll'
import {hashChangeController} from '@/lib/hashChangeController'
window.storageController = storageController;
window.responsiveController = responsiveController;
window.wrapperController = wrapperController;
window.smoothScroll = smoothScroll;
window.smoothScrollByHash = smoothScrollByHash;
window.hashChangeController = hashChangeController;

/* Vue コンポーネント */
// import * as vue_components from '@/vue_components';
// for(let component_name in vue_components){
//     Vue.component(component_name, vue_components[component_name]);
// }

/* Vue インスタンス */
import {vm_modal} from '@/instances/vm_modal'
import {vm_page_loading} from '@/instances/vm_page_loading'
import {vm_searchbox} from '@/instances/vm_searchbox'
window.vm_modal = createApp(vm_modal).use(store).mount('#modal');
window.vm_page_loading = createApp(vm_page_loading).mount('#page_loading');
// 検索メニューがない場合がある
if(document.getElementById('js-searchbox')){
    window.vm_searchbox = createApp(vm_searchbox).use(store).mount('#js-searchbox');
}

/* Swipeイベント */
import { useSwipe } from '@vueuse/core'
window.useSwipe = useSwipe;

/* productionモードではログ出力を抑制する */
const env = process.env.NODE_ENV || '???';
if(env === 'production'){
    console.log = function () {}
}
