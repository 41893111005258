import {isUndefined} from "../lib/isUndefined.js";

/**
 * アイテムのパラメータリスト
 * */
export const ItemAttributes = {
    name: "ItemAttributes",
    template:`
        <div v-if="item_attributes.length" :class="{'item_attributes': has_attribute_class, 'item_param': !has_attribute_class}">
            <div v-if="!has_attribute_class" class="item_param-list">
                <item_attribute v-for="(attr,idx) in formatted_item_attributes" :key="'attribute-'+idx" :attr="attr" :is_attributes="is_attributes"></item_attribute>
            </div>
            <template v-else>
                <item_attribute v-for="(attr,idx) in formatted_item_attributes" :key="'attribute-'+idx" :attr="attr" :is_attributes="is_attributes"></item_attribute>
            </template>
        </div>
        <p v-else class="par">パラメータがありません</p>
    `,
    props:{
        item_attributes:{
            type:Array,
            required:true,
            default(){
                return [];
            }
        },
        is_attributes: {
            type:Boolean,
            required:true,
            default:false
        }
    },
    computed:{
        /**
         * パラメータがあるかどうか
         * @return {Boolean}
         * **/
        has_attribute_class(){
            return !! (this.item_attributes.filter(item_attribute=>item_attribute.attribute_class)).length > 0;
        },
        /**
         * 表示するための整形したパラメータ情報
         * @return {Array}
         * */
        formatted_item_attributes() {
            let arr = [];
            this.item_attributes.forEach(item_attribute => {
                let formatted_attribute_class = null;
                if (!isUndefined(item_attribute.attribute_class)) {
                    formatted_attribute_class = {
                        class_name: '',
                        style_obj: null
                    };
                    if (item_attribute.attribute_class) {
                        item_attribute.attribute_class.split(' ').forEach(item => {
                            let split_item = item.split('-');
                            // レアリティ表示
                            if (split_item[0] === 'rarity') {
                                formatted_attribute_class['class_name'] = 'item_attributes-' + split_item[1].replace(/_\d$/, '');
                                formatted_attribute_class['type'] = split_item[0];
                                formatted_attribute_class['value'] = split_item[1].replace(/_\d$/, '');
                                formatted_attribute_class['num'] = (split_item[1].replace(/.+_(\d)$/, "$1")) * 1;
                            } else if (split_item[0] === 'color') {
                                // 色の形式は0x【6進数】の形式でアサインされる ex.0xFFFFFF
                                const color = split_item[1].replace(/^0x([a-zA-z0-9]{3,6})/, "$1");
                                formatted_attribute_class[split_item[0]] = color;
                                formatted_attribute_class['style_obj'] = {[split_item[0]]: '#' + color};
                            } else {
                                formatted_attribute_class[split_item[0]] = split_item[1];
                            }
                        });
                    }
                }
                arr.push({
                    ...item_attribute,
                    formatted_attribute_class
                });
            });
            return arr;
        },
    },
    components:{
        item_attribute:{
            name: "item_attribute",
            props:{
                attr:{
                    type:Object,
                    required:true
                },
                is_attributes:{
                    type:Boolean,
                    required:true
                }
            },
            template:`
                <dl v-if="!isHide">
                    <dt>{{ attr.trait_type }}</dt>
                    <dd v-if="attr.formatted_attribute_class && attr.formatted_attribute_class.class_name" :class="attr.formatted_attribute_class.class_name">
                        <!--star以外が追加されたら対応する必要がある-->
                        <svg v-for="i in attr.formatted_attribute_class.num" :key="attr.formatted_attribute_class.type+'-'+i" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><polygon :fill="'#'+attr.formatted_attribute_class.color" points="25.4,4.5 32,17.8 46.6,19.9 36,30.3 38.5,44.9 25.4,38 12.3,44.9 14.8,30.3 4.1,19.9 18.8,17.8 "></polygon></svg>
                    </dd>
                    <dd :style="attr.formatted_attribute_class && attr.formatted_attribute_class.style_obj ? attr.formatted_attribute_class.style_obj : ''">
                        {{getFormattedValue(attr)}}
                    </dd>
                </dl>
            `,
            methods:{
                getFormattedValue(attr){
                    if(!attr) {
                        console.log('attributeは必須')
                        return;
                    }
                    // 未出品
                    if(this.is_attributes){
                        return typeof attr.value === 'number' ? Math.trunc(attr.value) : attr.value;
                    }
                    return attr.value_type == 0 ? attr.string_value : attr.int_value;
                }
            },
            computed:{
                /**
                 * パラメータを非表示にするか（表示しない場合は検索パラメータとして利用）
                 * @return {Boolean}
                 * **/
                isHide() {
                    return this.attr.formatted_attribute_class && this.attr.formatted_attribute_class.display && this.attr.formatted_attribute_class.display === 'none';
                }
            }
        }
    }
};
