import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";

export const ModalSettingIcon= {
    name: "ModalSettingIcon",
    template:`
      <div :class="type+'-inner'">
        <div :class="type+'-title'">
          <div class="modal-title-inner">
            <h1>プロフィール画像設定</h1><a href="#" class="modal-close" @click.prevent="close">Close</a>
          </div>
        </div>
        <div :class="type+'-main'">
          <div class="l-content860">
            <div class="l-box-primary">
              <div class="l-profile_icon">
                <p class="par" v-if="item.nft_user_flag">プロフィール画像の設定を解除します。</p>
                <p class="par" v-else>プロフィール画像に設定します。</p>
                <p class="icon_profile" :class="{'is-pixelated':is_pixelated && !item.nft_user_flag}">
                  <!-- アイコン設定 -->
                  <img :src="image_url" :alt="image_alt" width="128" height="128">
                </p>
              </div>
            </div>
            <div class="l-two_choices">
              <div class="l-two_choices-back"><p class="button button-cancel"><a href="#" @click.prevent="close">キャンセル</a></p></div>
              <div class="l-two_choices-next">
                <form :action="next_url" method="post" ref="form">
                  <input type="hidden" name="_token" :value="CSRF_TOKEN">
                  <input type="hidden" name="token_realm_id" :value="item.token_realm_id">
                  <input type="hidden" name="token_short_kind" :value="item.token_short_kind">
                  <input type="hidden" name="token_index" :value="item.token_index">
                </form>
                <p class="button is-loading" v-if="is_loading"><a>{{ok_btn_label}}</a></p>
                <p class="button" v-else><a href="#" @click.prevent="submit">{{ok_btn_label}}</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    `,
    mixins:[modalDefaultMixin],
    emits:['close'],
    data(){
      return {
          CSRF_TOKEN:'',
          is_loading:false
      }
    },
    props:{
        store_props:{
            type:Object,
            required:true
        },
    },
    computed:{
        item(){
            return this.store_props.item;
        },
        /**
         * 商品画像のimage-rendering対応
         * @returns {Boolean}
         * */
        is_pixelated() {
            return !!this.item.thumbnail_pixel_url;
        },
        /**
         * 商品画像のURL
         * @returns {String}
         * */
        image_url(){
            if (this.item.nft_user_flag) {
                // NFTをアイコンに設定しているかどうか（アイコンを設定していたらデフォルト画像に、設定されていなかったら選択したアイテムをプロフィール画像に設定したい）
                return this.store_props.default_image_url;
            } else if (this.is_pixelated) {
                return this.item.thumbnail_pixel_url;
            }
            return this.item.thumbnail_s_url || this.item.image_url;
        },
        image_alt(){
            return this.item.nft_user_flag ? '' : this.item.name;
        },
        next_url(){
            return this.store_props.next_url;
        },
        ok_btn_label(){
            return this.item.nft_user_flag ? '解除する' : '設定する';
        }
    },
    methods:{
        submit(){
            if (!this.is_loading) {
                this.is_loading = true;
                this.$refs.form.submit();
            }
            return false;
        }
    },
    created() {
        this.CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    }
};
