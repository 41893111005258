import {Loading} from '@/components/Loading.js'

export const vm_page_loading= {
    name:'ThePageLoading',
    data(){
        return {
            is_show:false,
            is_fixed:true,
            sub:'page'
        };
    },
    methods:{
        show(){
            this.is_show = true;
        },
        hide(){
            this.is_show = false;
        }
    },
    template:'<Loading :is_show="is_show" :is_fixed="is_fixed" :sub="sub"></Loading>',
    components:{
        Loading
    }
};
