import {isUndefined} from "@/lib/isUndefined";

const state = {
    to_sales_status: null,
};

const getters = {
    /*
    * 商品が売れたかどうか
    * @param exhibit_status
    * @return {function(exhibit_status:number):Boolean}
    * */
    isSoldOf: (state) => (exhibit_status) => {
        return !isUndefined(exhibit_status) && (state.to_sales_status[exhibit_status] === 2 || state.to_sales_status[exhibit_status] === 3);
    }
};
const actions = {
    init({state, commit},payload) {
        // 初期値セット
        commit('setState',payload);
    }
};
const mutations = {
    setState(state, payload){
        // 初期値セット
        for(let key in payload){
            if (!isUndefined(state[key])) {
                state[key] = payload[key];
            }
        }
    }
};

const toSalesStatus = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

export { toSalesStatus as default}
