import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";

export const ModalWithdrawOKWithAlert = {
    name: "ModalWithdrawOKWithAlert",
    mixins: [modalDefaultMixin],
    emits: ['close'],
    template: `
    <div class="modal-title">
        <div class="modal-title-inner">
            <h1>退会事前確認</h1>
            <a href="#" class="modal-close" @click.prevent="close">Close</a>
        </div>
    </div>
    <div class="modal-main">
        <div class="l-content860">
            <div class="headline headline-min">
                <h2>ご確認ください</h2>
            </div>
            <div class="l-box-primary">
                <p class="par">退会すると、売上金残高が失効します。</p>
                <div class="l-options">
                    <div class="l-two_choices">
                        <div class="l-two_choices-back">
                            <p class="button button-cancel"><a href="#" @click.prevent="close">キャンセル</a></p>
                        </div>
                        <div class="l-two_choices-next">
                            <p class="button"><a href="#" @click.prevent="showInquiry">退会申請</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    props:{
        store_props:{
            type:Object,
            required:true
        },
    },
    methods: {
        showInquiry(){
            window.location.href = this.store_props.url;
        }
    }
};
