import ApiBase from './ApiBase'

const realm = {
    use_cache: true,
    params: [
        {
            name: 'service_id',
            required: true
        },
        {
            name: 'realm_id',
            required: true
        },
        {
            name: 'callback',
            required: true
        }
    ],
    send_param_names:['realm_id'],
    key_names: ['service_id','realm_id'],
}

const itemRealm = new ApiBase(realm);

export { itemRealm as default};