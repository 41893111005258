import {FormInput} from './FormInput.js'
import {FormRadio} from './FormRadio.js'
import {FormSelect} from './FormSelect.js'

export const FormPartsForItemAttributes = {
    name: "FormPartsForItemAttributes",
    template:`
          <div class="balloon balloon-regular" v-if="child_module_name === 'price_range' || child_module_name === 'min_max'">
              <div class="l-form-range">
                <FormInput :form_data="form_data.from" :values="values" :add_is_status="add_is_status_form_data" @update-val="updateVal"></FormInput>
                <span>～</span>
                <FormInput :form_data="form_data.to" :values="values" :add_is_status="add_is_status_form_data" @update-val="updateVal"></FormInput>
              </div>
          </div>
          <div class="balloon balloon-regular" v-else>
            <component :is="component" :form_data="form_data" :values="values" :add_is_status="add_is_status_form_data" @update-val="updateVal"></component>
          </div>
        <transition name="fade">
          <p class="balloon form_parts-error" v-if="err_msg" v-html="err_msg"></p>
        </transition>
    `,
    components:{
        FormInput,
        FormRadio,
        FormSelect,
    },
    emits:['update-val'],
    props:{
        /**
         * form_partsの名前
         */
        name:{
            type:String,
            required:true
        },
        /**
         * form_parts 見出し文字列
         */
        ttl_label:{
            type:String,
            required:true
        },
        /**
         * form_parts subモジュール文字列、1つ以上。 form_parts- 付きで使われる
         * また1つめは子供の form_hoge コンポーネントの情報の算出元なる
         */
        sub:{
            type:Array,
            required:true,
            validator(val){
                return val.length;
            }
        },
        /**
         * form_parts 状態class名 is- 付きで使われる
         */
        is_status:{
            type:Array,
            default(){
                return [];
            }
        },
        /**
         * form_parts 補足情報。見出し横につく
         */
        note:{
            type:String
        },
        /**
         * form_data。さらに子供のform_hoge コンポーネントに渡される
         */
        form_data:{
            type:Object,
            required:true
        },
        /**
         * value 情報のオブジェクト
         */
        values:{
            type:Object,
            required:true
        },
        /**
         * form_data ではなく、使用箇所によって状態classを渡す用
         */
        add_is_status_form_data:{
            type:Array,
            default(){
                return [];
            }
        },
        /**
         * エラーメッセージのオブジェクト form_data.name がキー名になっている
         */
        err_msgs:{
            type:Object,
            default(){
                return {};
            }
        },
    },
    computed:{
        /**
         * 子供のコンポーネント名を返す
         * this.child_module_name から判断する
         * @return {string}
         */
        component(){
            const component_name_list = {
                price : 'FormInput',
            }
            return component_name_list[this.child_module_name] ? component_name_list[this.child_module_name]: 'form'+this.toUpperCase(this.child_module_name);
        },
        /**
         * 子供のモジュール名を返す
         * @return {string}
         */
        child_module_name(){
            return this.sub[0];
        },
        /**
         * エラーメッセージを返す
         * @return {string}
         */
        err_msg(){
            return this.err_msgs[this.name] ? this.err_msgs[this.name].join('<br>') : '';
        },
        classes(){
            const sub = this.sub || [];
            const is_status = this.is_status || [];
            return sub.map( sub_str => 'form_parts-'+sub_str).concat(is_status.map( is_str => 'is-'+is_str));
        },
    },
    methods:{
        toUpperCase(str){
            return str.slice(0, 1).toUpperCase() + str.slice(1);
        },
        updateVal({name,val}){
            console.log('form_parts-updateVal',{[name]:val});
            this.$emit('update-val', {name,val});
        }
    }
};
