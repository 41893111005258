/**
 * スムーススクロール
 * @param {HTML element | string} tgt スクロール先のHTMLエレメントもしくはそのID文字列もしくはそのセレクタ文字列
 * @param {string} [easing]
 * @param {number} [adjust_pos] スクロール位置を調整(fixedメニュー対応)
 * @returns {boolean} aのonclickでreturn で指定されることも想定して常にfalse
 */

import {isString} from "./isString.js";
import {easingPattern} from "./easingPattern.js";

export const smoothScroll = function (tgt, easing, adjust_pos) {
    if (!adjust_pos) adjust_pos = window.scroll_adjust_pos ? window.scroll_adjust_pos : 0;
    let tgt_elm;
    if (!tgt) {
        return false;
    }
    if (adjust_pos && !isFinite(adjust_pos)) {
        adjust_pos = 0;
    }
    if (isString(tgt)) {
        if (tgt.match(/^#\S+$/)) {
            tgt_elm = document.getElementById(tgt.replace(/^#/, ''));
        } else {
            tgt_elm = document.querySelector(tgt);
        }
    } else if (tgt.nodeType && tgt.nodeType === 1) {
        tgt_elm = tgt;
    }
    if (!tgt_elm) {
        return;
    }

    let _scroll_tgt;
    let _start_pos = document.documentElement.scrollTop || document.body.scrollTop;
    let _end_pos = tgt_elm.getBoundingClientRect().top + _start_pos + (adjust_pos || 0);
    if (_end_pos < 0) {
        _end_pos = 0;
    }
    const _distance = _end_pos - _start_pos;
    const _speed = Math.abs(_distance) > 1333 ? 1000 : 750;
    const _easing = easing || 'easeOutQuad';
    const _ua = window.navigator.userAgent.toLowerCase();

    if ('scrollingElement' in document) {
        _scroll_tgt = document.scrollingElement;
    } else if (_ua.indexOf('msie') > -1 || _ua.indexOf('trident') > -1 || _ua.indexOf('firefox') > -1) {
        _scroll_tgt = document.getElementsByTagName('html')[0];
    } else {
        _scroll_tgt = document.getElementsByTagName('body')[0];
    }

    const _start_time = getTime();
    (function loop() {
        const _last_time = getTime();
        const _elapsed_time = Math.floor(_last_time - _start_time);
        let _percentage = (_elapsed_time / parseInt(_speed, 10));
        _percentage = (_percentage > 1) ? 1 : _percentage;
        let _current_position = _start_pos + (_distance * easingPattern(_easing, _percentage));
        if (_percentage === 1 || _elapsed_time > 10000) {
            _scroll_tgt.scrollTop = _current_position;
        } else {
            _scroll_tgt.scrollTop = _current_position;
            requestAnimationFrame(loop);
        }
    })();

    function getTime() {
        const now = window.performance && (performance.now || performance.mozNow || performance.msNow || performance.oNow || performance.webkitNow);
        return (now && now.call(performance)) || (new Date().getTime());
    }

    return false;
};

/**
 * #_hoge のハッシュで  #hoge HTMLエレメントへスムーススクロールする、smoothScroll にイベントとして登録する前提
 * @param hash {String}
 */
export const smoothScrollByHash = function (hash) {
    if (hash && hash.match(/^_/)) {
        smoothScroll('#' + hash.replace(/^_/, ''));
        if (window.history && window.history.replaceState) {
            setTimeout(function () {
                const path = window.location.pathname + window.location.search;
                const stateObj = {path, hash, title: 'afterSmoothScroll'};
                history.replaceState(stateObj, null, path);
            }, 2000);
        }
    }
};

/**
 * リセラガイド用のスムーススクロール
 * @param hash {String}
 */
export const smoothScrollByHashForGuide = function (hash) {
    if (hash && hash.match(/^_/)) {
        smoothScroll('#' + hash.replace(/^_/, ''), '', 0 - document.querySelector('.header').clientHeight);
        if (window.history && window.history.replaceState) {
            setTimeout(function () {
                const path = window.location.pathname + window.location.search;
                const stateObj = {path, hash, title: 'afterSmoothScroll'};
                history.replaceState(stateObj, null, path);
            }, 2000);
        }
    }
};
