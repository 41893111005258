import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";
import {addFigure} from "@/lib/addFigure";

export const ModalSaleItem = {
    name: "ModalSaleItem",
    mixins: [modalDefaultMixin],
    emits: ['close'],
    template: `
    <div class="modal-title">
        <div class="modal-title-inner">
            <h1>振込履歴</h1>
            <a href="#" class="modal-close" @click.prevent="close">Close</a>
        </div>
    </div>
    <div class="modal-main">
        <div class="l-content860">
            <div class="l-box-primary">
                <form :action="store_props.sales_list.withdrawal_url" method="post" ref="form">
                    <input type="hidden" name="_token" :value="CSRF_TOKEN">
                    <input type="hidden" name="withdrawal_id" :value="store_props.sales_list.withdrawal_id">
                    <input type="hidden" name="application_datetime" :value="store_props.sales_list.application_datetime">
                    <input type="hidden" name="confirmed_datetime" :value="store_props.sales_list.confirmed_datetime">
                    <div class="transfer_history">
                        <p class="transfer_history-status">振込 ({{store_props.status_labels}})
                        </p>
                        <dl class="transfer_history-account">
                            <dt>振込先</dt>
                            <dd>{{store_props.sales_list.bank_name}} {{store_props.sales_list.branch_name}}<br>{{store_props.sales_list.account_holde}}</dd>
                        </dl>
                        <div class="l-options">
                            <dl class="amount">
                                <dt>申請金額</dt>
                                <dd>&yen;{{application_amount}}</dd>
                            </dl>
                            <dl class="amount">
                                <dt>{{label_amount}}</dt>
                                <dd>&yen;{{amount}}</dd>
                            </dl>
                            <dl class="amount">
                                <dt>手数料</dt>
                                <dd>&yen;{{fee}}
                                    <p class="button_tiny" v-if="store_props.sales_list.status === 1 || store_props.sales_list.status === 3">
                                        <a href="#" @click.prevent="submit">請求明細</a>
                                    </p>
                                </dd>
                            </dl>
                            <dl class="transfer_history-date">
                                <dt>申請日時</dt>
                                <dd>{{store_props.sales_list.datetime}}</dd>
                            </dl>
                            <dl class="transfer_history-date">
                                <dt>{{label_transfer_date}}</dt>
                                <dd>{{ store_props.sales_list.transfer_date }}</dd>
                            </dl>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="l-btn_single">
            <p class="button button-cancel">
                <a href="#" @click.prevent="close">閉じる</a>
            </p>
        </div>
    </div>
    `,
    props:{
        store_props:{
            type:Object,
            required:true
        }
    },
    computed:{
        fee(){
            return this.store_props.sales_list.transfer_fee ? addFigure(Math.ceil(this.store_props.sales_list.transfer_fee)) : 0;
        },
        amount(){
            return this.store_props.sales_list.transfer_amount ? addFigure(Math.ceil(this.store_props.sales_list.transfer_amount)) : 0;
        },
        application_amount(){
            return this.store_props.sales_list.application_amount ? addFigure(Math.ceil(this.store_props.sales_list.application_amount)) : 0;
        },
        label_amount(){
            return ({2:'振込予定金額'})[this.store_props.sales_list.status] || '振込金額';
        },
        label_transfer_date(){
            return ({2:'振込予定日'})[this.store_props.sales_list.status] || '振込日';
        },
    },
    methods:{
        submit(){
            this.$refs.form.submit();
            return false;
        }
    },
    created() {
        this.CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    }
};
