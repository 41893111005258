import {Loading} from './Loading.js';
import {isUndefined} from '../lib/isUndefined.js';
import {addFigure} from '../lib/addFigure.js';

export const ItemTradeHistories = {
    name: "ItemTradeHistories",
    template:`
        <Loading v-if="is_loading" :is_show="is_loading" sub="min"></Loading>
        <div class="transaction_records" v-else>
            <table v-if="trade_histories.length">
                <thead>
                <tr>
                    <th>価格</th>
                    <th>販売者</th>
                    <th>購入者</th>
                    <th>取引日時</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="trade_history in trade_histories">
                    <td v-html="formatNumber(trade_history.price)"></td>
                    <td v-if="trade_history.user_name_from === null">退会したユーザー</td>
                    <td v-else>{{ trade_history.user_name_from }}</td>
                    <td v-if="trade_history.user_name_to === null">退会したユーザー</td>
                    <td v-else>{{ trade_history.user_name_to }}</td>
                    <td>{{ trade_history.formatted_date }}</td>
                </tr>
                </tbody>
            </table>
            <p v-else-if="!is_loading" class="par">{{ message }}</p>
            <slot></slot>
    </div>
    `,
    emits:['display'],
    props:{
        item:{
            type:Object,
            required:true
        },
        ajax_error_message:{
            type:String,
            required:true,
            default:'通信エラーが発生しました。時間をおいて再度お試しください。'
        }
    },
    data(){
        return {
            trade_histories:[],
            message:'',
            is_loading:false,
        }
    },
    computed:{
        API_URL() {
            return this.$store.getters['urls/getUrlOf']({key:'TRADE_HISTORIES'});
        }
    },
    created() {
        this.getTradeHistories();
    },
    methods:{
        /*
        * 取引履歴の取得
        * **/
        getTradeHistories() {
            this.is_loading = true;
            this.message = '';

            axios.get(this.API_URL, {
                params: {
                    token_realm_id: this.item.token_realm_id,
                    token_short_kind: this.item.token_short_kind,
                    token_index: this.item.token_index,
                },
            }).then(res => {
                    // エラーの場合
                    if (res.status === 200 && !res.data.errors) {
                        // 規約が更新された場合
                        if (!isUndefined(res.data.term_text_update_flag)) {
                            // 規約再同意画面へ遷移
                            location.replace(res.data.re_agreement_url);
                            return;
                        }
                        res.data.forEach(item => {
                            let formatted_date = '';
                            formatted_date = DateFns.format(DateFns.utcToZonedTime(new Date(item.created_at.replace(' ','T')+'Z'),this.$store.state.locale.timezone), 'yyyy/MM/dd HH:mm', {
                                timeZone: this.$store.state.locale.timezone,
                                locale: DateFns.locale.ja,//TODO localeをベタに書かない
                            });
                            item.formatted_date = formatted_date;
                            this.trade_histories.push(item);
                        });
                    } else {
                        console.log('取引履歴のデータ取得 失敗');
                        this.message = this.ajax_error_message;
                    }
            }).catch(err => {
                console.log(err);
                // axiosのエラーの場合
                this.message = this.ajax_error_message;
            }).finally(()=>{
                this.is_loading = false;
                this.$emit('display', !!this.trade_histories.length , !!this.message);
            });
        },
        /**
         * 表記を価格フォーマットにしたい
         * @param {number} num
         * @return {String}
         * */
        formatNumber(num) {
            return this.$store.state.locale.currency + addFigure(num);
        },
    },
    components:{
        Loading
    }
};
