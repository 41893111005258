import {isUndefined} from "@/lib/isUndefined";

const state = {
    is_open_stack:[],
    is_open:false,
    is_moving:false
};

const getters = {
    getState(state){
        return {
            is_open:state.is_open,
            is_moving:state.is_moving
        };
    }
};
const actions = {
    setIsOpen({state, commit},flg){
        // 処理中だったらstackに情報をたして何もしない
        if(state.is_moving){
            state.is_open_stack.push(flg);
            return;
        }
        commit('setState', {is_open:flg});
    },
    setIsMoving({state, commit},flg){
        commit('setState', {is_moving:flg});
        console.log('is_open_stack',state.is_open_stack.join(','));
        if(!flg && state.is_open_stack.length){
            commit('setIsOpen',state.is_open_stack.pop()) // 最後の処理
            commit('clearIsOpenStack');
        }
    }
};
const mutations = {
    setState(state,payload){
        for(let key in payload){
            if (!isUndefined(state[key])) {
                state[key] = payload[key];
            }
        }
    },
    clearIsOpenStack(state){
        state.is_open_stack.splice(0);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
