import ApiBase from './ApiBase'

const filter = {
    use_cache: true,
    params: [
        {
            name: 'service_id',
            default_value: '',
        },
        {
            name: 'callback',
            required: true
        }
    ],
    send_param_names:['service_id'],
    key_names: ['service_id'],
}

const itemFilter = new ApiBase(filter);

export { itemFilter as default};