import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";

export const ModalKycFeedback = {
    name: "ModalKycFeedback",
    mixins: [modalDefaultMixin],
    emits: ['close'],
    template: `
    <div :class="type+'-inner'">
        <p :class="type+'-msg'" v-html="store_props.msg"></p>
        <div class="alert-btn"><p class="button" :class="btn_class_name"><a href="#" @click.prevent="hide">{{btn_label}}</a></p></div>
    </div>
    `,
    props:{
        store_props:{
            type:Object,
            required:true
        },
    },
    computed:{
        btn_label(){
            return this.store_props.btn_label || 'OK';
        },
        btn_class_name(){
            return this.store_props.btn_class_name ?? '';
        }
    },
    methods:{
        hide(){
            this.close();
            if(this.store_props.close_callback && typeof this.store_props.close_callback === 'function'){
                console.log('close_callback');
                this.store_props.close_callback();
            }
        }
    },
};
