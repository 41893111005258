import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";
import {Loading} from "@/components/Loading";
import {isUndefined} from "@/lib/isUndefined";

export const ModalSavedSearchSelectToDelete = {
    name:'ModalSavedSearchSelectToDelete',
    components: {Loading},
    mixins:[modalDefaultMixin],
    emits:['close'],
    template:`
        <div :class="type+'-inner'">
        <div :class="type+'-title'">
            <div :class="type+'-title-inner'">
            <h1>検索条件保存</h1>
                <a href="#" @click.prevent="close" class="modal-close"></a>
            </div>
        </div>
        <div class="modal-main">
            <div class="l-content860">
                <div class="l-box-primary">
                    <p class="error_par">
                        検索条件の保存は最大10件です。<br>新しい検索条件を保存するには現在保存されている検索条件を削除してください。
                    </p>
                    <div class="l-query_list">
                        <div class="query" v-for="saved_search in store_props.saved_searches" :key="saved_search.id">
                            <div class="query-inner">
                                <span class="query-name">{{ saved_search.label }}</span>
                                <div class="query-delete">
                                    <a href="#" @click.prevent="deleteSavedSearches(saved_search.id)">Delete</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="l-btn_single">
            <p class="button button-cancel">
                <a href="#" @click.prevent="close">キャンセル</a>
            </p>
        </div>
    </div>
    `,
    data(){
        return {
            is_loading:false
        }
    },
    props:{
        store_props:{
            type:Object,
            required:true
        }
    },
    methods:{
        async deleteSavedSearches(delete_saved_search_id) {
            console.log('保存した検索条件を削除');
            if(this.is_loading) return;
            this.is_loading = true;
            this.close();
            vm_page_loading.show();
            const res = await axios.post(this.store_props.delete_api_url, {delete_saved_search_id})
                .catch(err => err.response)
                .finally(()=>{
                    this.is_loading = false;
                });
            vm_page_loading.hide();
            if (!res.data.errors) {

                // 規約が更新された場合
                if (!isUndefined(res.data.term_text_update_flag)) {
                    // 規約再同意画面へ遷移
                    location.replace(res.data.re_agreement_url);
                    return;
                }

                console.log('成功');
                this.store_props.close_callback(res.data.delete_saved_search_id);
            } else {
                this.openErrorMsg(res);
            }
        },
        openErrorMsg(res){
            console.log('openErrorMsg');
            if(res.data.errors.case_code){
                this.$store.dispatch('modal/openModalSimpleFeedback', {
                    lead: '['+res.data.errors.case_code+']'+'&nbsp;'+res.data.errors.case_message,
                });
            }else{
                this.$store.dispatch('modal/openModalSimpleFeedback', {
                    lead: res.data.errors.case_message,
                });
            }
        }
    }
};
