/**
 * popup もしくは fullpanel の表示を制御する vueインスタンス
 * @type {Vue}
 */
import {BaseModal} from "@/components/BaseModal.js";

export const vm_modal = {
    name:'TheModal',
    template: '<BaseModal></BaseModal>',
    components:{BaseModal}
};
