import items_of_base_form_parts_list from "@/const/items_of_base_form_parts_list";
/**
 * @typedef FormDataOption
 * @prop {string} label
 * @prop {string|number} value
 */
/**
 * @typedef FormData
 * @prop {string} form_data の name。
 * @prop {string[]} [sub] CSSのサブモジュールの文字列。
 * @prop {string} [type]
 * @prop {FormDataOption[]} checkbox、radio select の選択肢
 */
/**
 * @typedef FormParts
 * @prop {string} ttl_label 見出し文字列
 * @prop {string} name form_parts を区別するname
 * @prop {string[]} sub CSSのサブモジュールの文字列。1つめで使用コンポーネントを判断するので、1つ以上の指定必須
 * @prop {string[]} [is_status] CSSの is-系となる。is-を省いた文字列
 * @prop {FormData|{from:FormData,to:FormData}} [form_data]
 * @prop {string} [kind] リストをフィルタリングする際に使用
 */
/**
 * パラメータ系情報
 * @type {Object.<FormParts[]>}
 */
const base_form_parts_list = {
    /* 検索結果一覧、サービス別商品一覧、ユーザ別商品一覧 */
    items:items_of_base_form_parts_list,
}
export default base_form_parts_list
