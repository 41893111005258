import {BaseButton} from './BaseButton.js';

export const BtnItemAttributes = {
    name: "BtnItemAttributes",
    template:`
        <BaseButton :url="url" :addClasses="addClasses" :isExternal="isExternal">パラメータ詳細</BaseButton>
    `,
    data(){
      return {
          isExternal:true,
          addClasses:['button-cancel']
      }
    },
    props:{
        url:{
            type:String,
            required:true
        }
    },
    components:{
        BaseButton
    }
}
