import {isString} from '@/lib/isString'
import {getStrParamsReplaced} from '@/lib/getStrParamsReplaced'

const state = {
    INDEX: null,
    CURRENT: null,
    IMAGE: null,
    ITEM_DETAIL: null,
    ITEM_DETAIL_TOTAL_MARKET: null,
    EXHIBIT_INPUT: null,
    EXHIBIT_EDIT: null,
    USER_ITEMS: null,
    DRAFT_LIST: null,
    EXHIBIT_LIST: null,
    SEARCH_ITEMS: null,
    SERVICE_ITEMS: null,
    GET_FILTER: null,
    GET_CATEGORY: null,
    SAVE_SEARCH: null,
    SAVE_SEARCH_LIST: null,
    MYPAGE_SAVE_SEARCH_LIST: null,
    MYPAGE_NOTIFICATION_DETAIL: null,
    MYPAGE_NEWS_DETAIL: null,
    BANNER: null,
    AUCTION_BID_LIST: null,
    TRADE_HISTORIES: null,
};

const getters = {
    /**
     * @typedef {Object|string} PayloadOfGetUrlOf
     * @property {string} key - オブジェクトの場合のみ … 対象のキー
     * @property {Object} params - オブジェクトの場合のみ … replace用の情報
     */
    /**
     * 渡された key の URLを返す
     * @param state
     * @return { function(PayloadOfGetUrlOf):string }
     */
    getUrlOf: (state) => (payload) => {
        const key = isString(payload) ? payload : payload.key;
        const params = payload.params || {};
        if (state[key].replace_str) {
            if (key === 'IMAGE' && /^https?/.test(params.image_url)) {
                return params.image_url;
            }
            return getStrParamsReplaced({base_str: state[key].base_str, replace_str: state[key].replace_str, params});
        }
        return state[key].base_str;
    },
    getBannerUrlOf: (state) => (payload) => {
        const key = isString(payload) ? payload : payload.key;
        const params = payload.params || {};
        if (state[key].replace_str) {
            if (key === 'BANNER' && /^https?/.test(params.banner_url)) {
                return params.banner_url;
            }
            return getStrParamsReplaced({base_str: state[key].base_str, replace_str: state[key].replace_str, params});
        }
        return state[key].base_str;
    }
};
const actions = {
    init({state, commit, dispatch}, payload) {
        // 初期値セット
        commit('setState', payload);
    }
};
const mutations = {
    setState(state, payload) {
        // 初期値セット
        for (let key in payload) {
            if (state[key] === null) {
                state[key] = {
                    base_str: payload[key].base_str,
                    replace_str: (payload[key].replace_str || '')
                };
                // TODO 消す
                // Vue.set(state, key, {
                //     base_str: payload[key].base_str,
                //     replace_str: (payload[key].replace_str || '')
                // })
            }
        }
    }
};

const urls = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
export {urls as default};
