import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";

export const ModalSavedSearchToConfirmDelete = {
    name: "ModalSavedSearchToConfirmDelete",
    mixins: [modalDefaultMixin],
    emits: ['close'],
    template: `
    <div class="alert-inner">
        <p class="alert-msg" v-html="lead"></p>
        <div class="alert-btn_area">
            <div class="alert-btn"><p class="button button-cancel "><a href="#" @click.prevent="close">{{close_btn_label}}</a></p></div>
            <div class="alert-btn"><p class="button"><a href="#" @click.prevent="onClickBtn">{{delete_btn_label}}</a></p></div>
        </div>
    </div>
    `,
    data(){
        return {
            is_loading:false
        }
    },
    props:{
        store_props:{
            type:Object,
            required:true
        }
    },
    computed:{
        lead(){
            return this.store_props.lead || '';
        },
        close_btn_label(){
            return this.store_props.close_btn_label || 'キャンセル';
        },
        delete_btn_label(){
            return this.store_props.delete_btn_label || '削除';
        }
    },
    methods:{
        onClickBtn(){
            if(this.is_loading) return;
            this.is_loading = true;
            this.store_props.close_callback();
            this.close();
        }
    },
};
