import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";

export const ModalSimpleFeedback = {
    name: "ModalSimpleFeedback",
    mixins: [modalDefaultMixin],
    emits: ['close'],
    template: `
    <div :class="type+'-inner'">
        <p :class="type+'-msg'" v-html="lead"></p>
        <div :class="type+'-btn'"><p class="button"><a href="#" @click.prevent="onClickOkBtn">OK</a></p></div>
    </div>
    `,
    props:{
        store_props:{
            type:Object,
            required:true
        }
    },
    computed:{
        lead(){
            return this.store_props.lead || '';
        },
    },
    methods:{
        onClickOkBtn(){
            // ローカルストレージに格納されているモーダル表示維持判定を削除する
            storageController.removeData(this.store_props.LS_KEY);

            if(this.store_props.link_url){
                location.href = this.store_props.link_url;
            } else {
                this.close();
            }
        }
    },
};
