/**
 * 商品検索結果、サービス別商品一覧、ユーザ別商品一覧で使用する検索パラメータ
 */
export default [
    {
        ttl_label:'対象タイトル',
        name:'game',
        sub:['select'],
        is_status:[],
        form_data: {
            name: 'service_id',
            list: [],
            disabled:false,
            default_value:null
        },
        kind:'service'
    },
    {
        ttl_label:'カテゴリ',
        name:'realm_id',
        sub:['select'],
        is_status:[],
        form_data:{
            name:'realm_id',
            list:[],
            default_value:''
        },
        kind:'realm'
    },
    {
        ttl_label:'キーワード',
        name:'keyword',
        sub:['input'],
        is_status:[],
        form_data: {
            name: 'keyword',
            default_value:''
        },
        kind:'other'
    },
    {
        ttl_label:'販売価格',
        name:'price_range',
        sub:['price_range'],
        is_status:[],
        form_data: {
            from:{
                name: 'price_min',
                sub: [],
                type: 'number',
                default_value:''
            },
            to:{
                name: 'price_max',
                sub: [],
                type: 'number',
                default_value:''
            }
        },
        kind:'other'
    },
    {
        ttl_label:'販売者',
        name:'seller_type_selection',
        sub:['radio'],
        is_status:[],
        form_data: {
            name: 'seller_type_selection',
            list: [],
            default_value:0
        },
        kind:'other'
    },
    {
        ttl_label:'販売状況',
        name:'exhibit_status_selection',
        sub:['radio'],
        is_status:[],
        form_data: {
            name: 'exhibit_status_selection',
            list: [],
            sort_index:[1,2,0],
            default_value:1
        },
        kind:'other'
    },
    {
        ttl_label:'販売形式',
        name:'sales_type_selection',
        sub:['radio'],
        is_status:[],
        form_data: {
            name: 'sales_type_selection',
            list: [],
            sort_index:[0,2,1],
            default_value:0
        },
        kind:'other'
    },
    {
        ttl_label:'並び順',
        name:'sort_type_selection',
        sub:['radio'],
        is_status:[],
        form_data: {
            name: 'sort_type_selection',
            list: [],
            default_value:0
        },
        kind:'other'
    },
]
