import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";

export const ModalBankRegister = {
    name: "ModalBankRegister",
    mixins: [modalDefaultMixin],
    emits: ['close'],
    template: `
    <div class="modal-title">
        <div class="modal-title-inner">
            <h1>口座情報確認</h1>
            <a href="#" class="modal-close" @click="hide('cancel')">Close</a>
        </div>
    </div>
    <div class="modal-main">
        <div class="l-content860">
            <div class="l-box-primary">
                <p class="par">振込先は下記の口座でよろしいですか。</p>

                <div class="l-form">
                    <div class="l-form-headline"><dt>金融機関</dt></div>
                    <p class="l-form_confirm">{{store_props.bankName}}</p>
                    <div class="l-form-headline"><dt>支店</dt></div>
                    <p class="l-form_confirm">{{store_props.bankBranchName}}</p>
                    <div class="l-form-headline"><dt>口座種別</dt></div>
                    <p class="l-form_confirm">{{store_props.bankAccountType}}</p>
                    <div class="l-form-headline"><dt>口座番号</dt></div>
                    <p class="l-form_confirm">{{store_props.bankAccountNumber}}</p>
                    <div class="l-form-headline"><dt>口座名義</dt></div>
                    <p class="l-form_confirm">{{store_props.familyNameJaKanaJP}} {{store_props.givenNameJaKanaJP}}</p>
                </div>
                <div class="l-options">
                    <div class="l-two_choices">
                        <div class="l-two_choices-back">
                            <p class="button button-cancel" v-if="store_props.isPushBack"><a href="#" @click.prevent="hide('cancel')">戻る</a></p>
                            <p class="button button-cancel is-disabled" v-else><a>戻る</a></p>
                        </div>
                        <div class="l-two_choices-next">
                            <p class="button" v-if="store_props.isPushNext">
                                <a href="#" @click.prevent="hide('register')" v-if="store_props.isNewRegistration">登録</a>
                                <a href="#" @click.prevent="hide('register')" v-else>変更</a>
                            </p>
                            <p class="button is-disabled" v-else><a>次へ</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    props:{
        store_props:{
            type:Object,
            required:true
        }
    },
    methods:{
        hide(action){
            this.close();
            if(this.store_props.close_callback && typeof this.store_props.close_callback === 'function'){
                console.log('close_callback');
                this.store_props.close_callback(action);
            }
        }
    }
};
