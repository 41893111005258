const responsiveController = (function(breakpoint_settings){
    // MediaQueryList オブジェクト
    const mql = {};
    // ブレイクポイント切り替え時に発動するイベント
    const events = [];
    // ブレイクポイントの設定
    const _breakpoint_settings = breakpoint_settings && Array.isArray(breakpoint_settings) && breakpoint_settings.length && breakpoint_settings[0].device ? breakpoint_settings : [
        {device:'sp',width:0},
        {device:'pad',width:768},
        {device:'pc',width:980}
    ];
    // MediaQueryList が リスナーを使えるか
    const matchMediaAddListenerSupported = !!(window.matchMedia && window.matchMedia( 'all' ).addListener);

    // MediaQueryList オブジェクトの生成＆リスナーセット
    _breakpoint_settings.forEach(function(setting){
        if(isFinite(setting.width) && setting.width > 0){
            if(matchMediaAddListenerSupported){
                mql[setting.device] = window.matchMedia('screen and (min-width: '+setting.width+'px)');
                mql[setting.device].addListener(checkBreakPoint);
            }
        }
    });

    // MediaQueryList オブジェクトのリスナーから呼ばれる
    function checkBreakPoint(mql){
        events.forEach(function(event){
            const devide = getCurrentDevice();
            event.fn.call(event.parent,devide,event.params);
        });
    }

    function getCurrentDevice(){
        let _device = _breakpoint_settings[0].device;
        _breakpoint_settings.forEach(function(setting){
            if(mql[setting.device] && mql[setting.device].matches){
                _device = setting.device;
            }
        });
        return _device;
    }

    return {
        /** @function setEvents
         * ブレイクポイントで切り替わるときに発動するイベントを登録する
         * @this responsiveController
         * @param {function|object.<fn,parent,params,first>} event
         */
        setEvents:function(event){
            if(typeof event === 'function' ){
                events.push({
                    parent:window,
                    fn:event,
                    params:{}
                });
            }else if(typeof event.fn === 'function'){
                events.push({
                    parent:event.parent||window,
                    fn:event.fn,
                    params:event.params||{}
                });
                /* first が指定されていたら、イベントセット時にも処理する*/
                if(event.first){
                    const device = getCurrentDevice();
                    const delay = isFinite(event.first)?event.first:0;
                    setTimeout(()=>{
                        event.fn.call(event.parent,device,event.params);
                    },delay);
                }
            }
            return;
        },
        /** @function getCurrentDevice
         * ブレイクポイントの設定から、現在のデバイスの文字列をかえす
         * @this responsiveController
         * @return {string}
         */
        getCurrentDevice:getCurrentDevice
    };
})();

export { responsiveController as default};
