import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";
import {isUndefined} from "@/lib/isUndefined";

export const ModalSavedSearchToSave = {
    name: "ModalSavedSearchToSave",
    mixins: [modalDefaultMixin],
    emits: ['close'],
    template: `
    <div class="modal-title">
        <div class="modal-title-inner">
            <h1>検索条件保存</h1>
            <a href="#" class="modal-close" @click.prevent="close">Close</a>
        </div>
    </div>
    <div class="modal-main">
        <div class="l-content860">
            <div class="l-box-primary">
                <p v-if="delete_saved_search_label" class="error_par">検索条件「{{delete_saved_search_label}}」を削除しました。<br>新しい検索条件を保存できます。</p>
                <div class="l-form">
                    <dl class="l-form-headline">
                        <dt>検索条件保存名 <span class="is-required">※必須</span></dt>
                        <dd></dd>
                    </dl>
                    <div class="form_input">
                        <input type="text" inputmode="text" placeholder="入力してください" v-model.trim="new_label" @keydown="is_first=false" v-focus="handleFocus" v-blur="handleBlur" :style="input_style">
                    </div>
                    <transition name="fade">
                        <p v-if="!is_first && errorMsg" class="balloon">{{errorMsg}}</p>
                    </transition>
                </div>
                <div class="l-form">
                    <dl class="l-form-headline">
                        <dt>通知</dt>
                    </dl>
                    <div class="form_checkbox">
                        <label><input type="checkbox" v-model="new_notification_flag"><span></span>該当商品の出品通知を受け取る</label>
                    </div>
                </div>
                <div class="l-two_choices">
                    <div class="l-two_choices-back">
                        <p class="button button-cancel">
                            <a href="#" @click.prevent="close">キャンセル</a>
                        </p>
                    </div>
                    <div class="l-two_choices-next">
                        <p class="button is-loading" v-if="is_loading">
                            <a>保存する</a>
                        </p>
                        <p class="button" v-else-if="is_first||!errorMsg">
                            <a href="#" v-if="mode==='new_save'" @click.prevent="saveSearch">保存する</a>
                            <a href="#" v-else-if="mode==='update'" @click.prevent="updateSavedSearches">保存する</a>
                        </p>
                        <p class="button is-disabled" v-else>
                            <a>保存する</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    props:{
        store_props:{
            type:Object,
            required:true
        },
    },
    data() {
        return {
            is_first:true,
            new_label:'',
            new_notification_flag: true,
            is_loading:false,
            on_focus:false,
            link_url:''
        };
    },
    mounted(){
        this.new_label = this.decodeSpecialChars(this.store_props.saved_search.label);
        this.new_notification_flag = this.store_props.saved_search.notification_flag;
        this.link_url = this.store_props.saved_search.link_url;
    },
    computed:{
        mode(){
            return this.store_props.mode;
        },
        delete_saved_search_label(){
            return this.store_props.saved_search.delete_saved_search_label || '';
        },
        errorMsg(){
            if (this.new_label == '' || this.new_label == null) {
                return '検索条件保存名を入力してください';
            } else if (this.new_label.length > this.store_props.max_label) {
                const error_max_label = '検索条件名に設定できる文字数は__MAX_LABEL__文字です。'; // 多言語化に備えて
                return error_max_label.replace('__MAX_LABEL__',this.store_props.max_label);
            }
            return '';
        },
        input_style(){
            return this.on_focus ? '' : 'text-overflow: ellipsis;';
        }
    },
    methods:{
        async saveSearch() {
            this.is_first = false;
            if (this.errorMsg || this.is_loading) return;
            console.log('saveSearch');

            this.is_loading = true;
            this.close();
            const res = await axios.post(this.$store.getters['urls/getUrlOf']('SAVE_SEARCH'), {
                label: this.new_label,
                notification_flag: this.new_notification_flag === true ? 1 : 0,
                ...this.$store.getters['mainParams/getValuesObjForApiParams']({})
            })
                .catch(err => err.response);
            console.log(res);
            if (res.status === 200 && !res.data.errors) {
                // 規約が更新された場合
                if (!isUndefined(res.data.term_text_update_flag)) {
                    // 規約再同意画面へ遷移
                    location.replace(res.data.re_agreement_url);
                    return;
                }
                this.$store.dispatch('modal/openModalSimpleFeedback', {
                    lead: '検索条件を保存しました',
                });
            } else {
                this.openErrorMsg(res);
            }
            this.is_loading = false;
        },
        async updateSavedSearches(){
            console.log('保存した検索条件を更新');
            this.is_first = false;
            if (this.errorMsg || this.is_loading) return;

            this.is_loading = true;
            this.close();
            // 新しいラベルと通知に書き換え
            this.store_props.saved_search.label = this.new_label;
            if(this.new_notification_flag === true){
                this.store_props.saved_search.notification_flag = 1;
            }else{
                this.store_props.saved_search.notification_flag = 0;
            }
            const res = await axios.post(this.store_props.update_api_url, {
                saved_search_id: this.store_props.saved_search.id,
                label: this.store_props.saved_search.label,
                notification_flag: this.store_props.saved_search.notification_flag,
                query: this.store_props.saved_search.query,
            })
                .catch(err => err.response)
                .finally(()=>{
                    this.is_loading = false;
                });
            console.log(res);
            if (res.status === 200 && !res.data.errors) {
                // 規約が更新された場合
                if (!isUndefined(res.data.term_text_update_flag)) {
                    // 規約再同意画面へ遷移
                    location.replace(res.data.re_agreement_url);
                    return;
                }

                console.log('更新した');
                this.$store.dispatch('modal/openModalSimpleFeedback', {
                    lead: '検索条件を更新しました',
                });
            } else {
                this.openErrorMsg(res);
            }
        },
        openErrorMsg(res){
            console.log('openErrorMsg');
            this.$store.dispatch('modal/openModalAjaxError', {
                status:res.status,
                errors:res.data.errors,
                messages:this.store_props.ajax_error_messages
            });
        },
        decodeSpecialChars(text){
            return text.replace(/(&amp;|&#039;|&quot;|&lt;|&gt;)/g, (match) => {
                return {
                    '&amp;': '&',
                    '&#039;': '\'',
                    '&quot;': '"',
                    '&lt;': '<',
                    '&gt;': '>'
                }[match]
            })
        },
        handleFocus(e,el){
            this.on_focus = true;
        },
        handleBlur(e,el){
            this.on_focus = false;
        },
    },
    directives:{
        'focus':{
            mounted: function (el, binding) {
                let f = function (evt) {
                    if (binding.value(evt, el)) {
                        el.removeEventListener('focus', f, { passive: true });
                    }
                }
                el.addEventListener('focus', f, {passive: true});
            }
        },
        'blur':{
            mounted: function (el, binding) {
                let f = function (evt) {
                    if (binding.value(evt, el)) {
                        el.removeEventListener('blur', f, { passive: true });
                    }
                }
                el.addEventListener('blur', f, {passive: true});
            }
        }
    },
};
