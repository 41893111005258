/**
 * Storageの制御（Storageが使用可能か、キーを保存、取得、削除）
 *
 */
let storageController = (function(){
    /**
     * localStorage の機能検出
     * @param type
     * @returns {*|boolean}
     * ex. https://developer.mozilla.org/ja/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
     */
    function _storageAvailable(type) {
        var storage;
        try {
            storage = window[type];
            var x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return e instanceof DOMException && (
                    // everything except Firefox
                    e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === 'QuotaExceededError' ||
                    // Firefox
                    e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                (storage && storage.length !== 0);
        }
    }

    return {
        /**
         * Storageのデータを取得
         * @param {string} LS_KEY localStorageのキーを指定
         * @return {object,null}
         * */
        getData: function(LS_KEY){
            if(!_storageAvailable('localStorage')){
                console.log('localStorageが使用できません');
                return;
            }
            if(!LS_KEY){
                console.log('localStorageのkey名を指定してください');
                return;
            }
            var ls_data =localStorage.getItem(LS_KEY);
            if(!ls_data) return null;
            // 意図しないデータがはいっていた場合を考慮
            try {
                return JSON.parse(ls_data);
            }
            catch(e) {
                return null;
            }
        },
        /**
         * Storageにキーを指定してデータを追加または、すでに存在するキーの場合は更新
         * @param {string} LS_KEY localStorageのキーを指定
         * @param {string,number,object,null} value 追加、更新する値
         * */
        setData:function(LS_KEY,value){
            if(!_storageAvailable('localStorage')){
                console.log('localStorageが使用できません');
                return;
            }
            if(!LS_KEY){
                console.log('対象のkeyを指定してください');
                return;
            }
            localStorage.setItem(LS_KEY, JSON.stringify(value));
        },
        /**
         * Storage からキーを削除
         * @param {string} LS_KEY localStorageのキーを指定
         * */
        removeData:function(LS_KEY){
            if(!_storageAvailable('localStorage')){
                console.log('localStorageが使用できません');
                return;
            }
            if(!LS_KEY){
                console.log('対象のkeyを指定してください');
              return;
            }
            localStorage.removeItem(LS_KEY);
        },
    };
})();

export { storageController as default};
