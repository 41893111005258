import {isUndefined} from "@/lib/isUndefined";
import {decodeSpecialChars} from "@/lib/decodeSpecialChars";

const state = {
    keyword:'',
    placeholder:'',
    search_summary:'',
    old:{
        keyword:'',
        placeholder:'',
        search_summary:'',
    }
};

const getters = {
    getState(state){
        return state;
    },

};
const actions = {
    init({state, commit},payload) {
        // 初期値セット
        const setStateObj = {};
        for (let key in payload) {
            if(key==='keyword' || key==='search_summary'){
                // エスケープされていたら戻したい
                setStateObj[key] = decodeSpecialChars(payload[key]);
            } else {
                setStateObj[key] = payload[key];
            }
        }
        // 初期値を古い値として保持したい
        setStateObj['old'] = {...setStateObj};
        commit('setState',setStateObj);
    },
    clear({state,commit}){
        commit('setState',{
            keyword:'',
            placeholder:'',
            search_summary:'',
            old:{
                keyword:state.keyword,
                placeholder:state.placeholder,
                search_summary:state.search_summary,
            }
        });
    }
};
const mutations = {
    setState(state, payload){
        for(let key in payload){
            if (!isUndefined(state[key])) {
                state[key] = payload[key];
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
