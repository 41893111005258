import utcToZonedTime from "date-fns-tz/utcToZonedTime";
import {differenceInMonths} from "date-fns";

const state = {
    locale:'',
    timezone:'',
    now:null,
    currency:''
};

const getters = {
    state(state){
        return state;
    },
    getLocale(){
        return state;
    },
    getUtcToZonedTime:(state) => (time) => {
        // ex. UTC基準 2023-03-20T12:00:00Z
        return utcToZonedTime(new Date(time.replace(' ','T')+'Z'), state.timezone);
    },
    getFormatDistance:(state,getters) => (now= new Date(), tgt_time='', is_b2c_sale = false) =>{
            if(!tgt_time){
                console.log('対象の日付の指定は必須です');
                return ;
            }
            const tgt_zoned_time = getters.getUtcToZonedTime(tgt_time);
            const diff_date = tgt_zoned_time - now;

            // 意図しないデータだったら
            if (isNaN(diff_date)) return '';

            if (is_b2c_sale) {
                // B2C定額販売で1ヶ月以上の場合は残り月数を返却
                const diff_months = differenceInMonths(tgt_zoned_time,now);
                if (diff_months > 0) {
                    return diff_months + "ヶ月";
                }
            }

            const days = Math.trunc(diff_date / (1000 * 60 * 60 * 24));
            const hours = Math.trunc(diff_date / (1000 * 60 * 60)) - (days * 24);
            const minutes = Math.trunc(diff_date / (1000 * 60)) - (days * 24 * 60) - (hours * 60);
            const seconds = Math.trunc(diff_date / 1000) - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
            let time = '';
            if (days !== 0) {
                time = days + "日";
            } else if (hours !== 0) {
                time = hours + "時間";
            } else if (minutes !== 0) {
                time = minutes + "分";
            } else {
                time = seconds + "秒";
            }
            return time;
    }
};

const actions = {
    init({commit},payload) {
        // 初期値セット
        commit('setState',payload);
    }
};

const mutations = {
    setState(state, payload){
        // 初期値セット
        for (let key in payload) {
            state[key] = payload[key];
        }
    }
};
const locale = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
export {locale as default}
