import { mapState, mapMutations, mapActions } from 'vuex'
import wrapperController from "@/lib/wrapperController"

export const BaseModal = {
    name: "BaseModal",
    template:`
      <template v-if="type==='modal'">
          <transition name="modal" :duration="{ enter: DURATION, leave: DURATION }" @before-enter="onBeforeEnter" @after-enter="onAfterEnter" @after-leave="onAfterLeave">
              <div v-if="is_active" :class="class_obj">
                  <component :is="component" :type="type" :store_props="store_props" @close="close"></component>
              </div>
          </transition>
      </template>
     <template v-else>
         <transition name="alert" :duration="{ enter: DURATION, leave: DURATION }" @before-enter="onBeforeEnter" @after-enter="onAfterEnter" @after-leave="onAfterLeave">
             <div v-if="is_active" :class="class_obj">
                 <component :is="component" :type="type" :store_props="store_props" @close="close"></component>
             </div>
         </transition>
     </template>
    `,
    data(){
        return {
            /* {boolean} is_active DOMにレンダリングするかどうかのフラグ */
            is_active: false,
            /* {boolean} is_rendered DOMのレンダリングが完了したかどうかのフラグ */
            is_rendered: false,
            /* モーダルOPEN,CLOSEのアニメーション時間 */
            DURATION:400,
        }
    },
    computed:{
        ...mapState('modal',['is_show','component','type','store_props','tmpl_name']),
        /**
         * $el に適用される classを算出
         * @return {object}
         */
        class_obj: function () {
            return {
                [this.type]:true,
                [this.type + '-' + this.tmpl_name]:!!this.tmpl_name,
            }

        },
    },
    mounted: function () {
    },
    methods: {
        ...mapMutations('modal',['setType','isClosing']),
        ...mapActions('modal',['resetStateAfterModalClosed']),
        /**
         * component に変更があり !!component のときに呼ばれる
         */
        open(){
            this.is_active = true;
        },
        /**
         * modalを閉じる
         */
        close() {
            this.is_active = false;
            // closing 中であることを store.modal に伝える
            this.isClosing();
        },
        /**
         * dataの値を初期値に戻す
         */
        resetData: function () {
            const data_def = {
                is_active: false,
                is_rendered: false,
            };
            for (var i in data_def) {
                this[i] = data_def[i];
            }
            this.resetStateAfterModalClosed();
        },
        /**
         * modal表示 要素が DOM に挿入される前
         */
        onBeforeEnter(){
            if (this.type === 'modal') {
                wrapperController.hide();
            } else if(this.type === 'alert'){
                wrapperController.fixed();
            }
        },
        /**
         * modal表示 トランジションが完了したとき
         * */
        onAfterEnter: function () {
            this.is_rendered = true;
        },
        /**
         * modal非表示 トランジションが完了して要素が DOM から取り除かれた時
         */
        onAfterLeave(){
            this.is_rendered = false;
            // modalを非表示にしてからwrapperの対応
            if(this.type === 'modal'){
                wrapperController.show();
            } else if(this.type === 'alert'){
                wrapperController.cancelFixed();
            }
            // wrapperの処理がおわったら
            setTimeout(this.resetData, this.DURATION);
        }
    },
    watch:{
        async component(new_val,old_val){
            if(new_val){
                // 続いて開くときに nexttick を挟んでおかないと showAnime が反応しない
                await this.$nextTick();
                this.open();
            }else if(this.is_rendered){
                // component が空なのに is_rendered が true なら削除処理（ここにくるパターンはないはずだが念のため）
                this.close();
            }
        },
        is_show(new_val, old_val){
            if(!new_val && this.is_rendered){
                this.close();
            }
        }
    }
};
