/**
 * easingPattern
 * @param type
 * @param time
 * @returns {*}
 */
export const easingPattern = (type, time) => {
    let pattern;
    if (type === 'easeInQuad') pattern = time * time; // accelerating from zero velocity
    if (type === 'easeOutQuad') pattern = time * (2 - time); // decelerating to zero velocity
    if (type === 'easeInOutQuad') pattern = time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time; // acceleration until halfway, then deceleration
    if (type === 'easeInCubic') pattern = time * time * time; // accelerating from zero velocity
    if (type === 'easeOutCubic') pattern = (--time) * time * time + 1; // decelerating to zero velocity
    if (type === 'easeInOutCubic') pattern = time < 0.5 ? 4 * time * time * time : (time - 1) * (2 * time - 2) * (2 * time - 2) + 1; // acceleration until halfway, then deceleration
    if (type === 'easeInQuart') pattern = time * time * time * time; // accelerating from zero velocity
    if (type === 'easeOutQuart') pattern = 1 - (--time) * time * time * time; // decelerating to zero velocity
    if (type === 'easeInOutQuart') pattern = time < 0.5 ? 8 * time * time * time * time : 1 - 8 * (--time) * time * time * time; // acceleration until halfway, then deceleration
    if (type === 'easeInQuint') pattern = time * time * time * time * time; // accelerating from zero velocity
    if (type === 'easeOutQuint') pattern = 1 + (--time) * time * time * time * time; // decelerating to zero velocity
    if (type === 'easeInOutQuint') pattern = time < 0.5 ? 16 * time * time * time * time * time : 1 + 16 * (--time) * time * time * time * time; // acceleration until halfway, then deceleration
    return pattern || time; // no easing, no acceleration
};
