import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";
import {Loading} from "@/components/Loading";
import {isUndefined} from "@/lib/isUndefined";

export const ModalGuide = {
    name: "ModalGuide",
    mixins: [modalDefaultMixin],
    emits: ['close'],
    template: `
    <div :class="type+'-inner'">
        <div :class="type+'-title'">
            <div :class="type+'-title-inner'">
                <h1>{{ title }}</h1><a href="#" :class="type+'-close'" @click.prevent="close">Close</a>
            </div>
        </div>
        <div :class="type+'-main'">
            <div :class="type+'-main-inner'">
                <div class="l-content860">
                    <loading :is_show="is_loading"></loading>
                    <template v-if="!is_loading">
                        <div v-if="html" v-html="html"></div>
                        <p v-else class="par">エラーが発生しました</p><!-- 通常は発生しない -->
                    </template>
                </div>
                <div v-if="!is_loading" class="l-btn_single">
                    <p class="button button-cancel">
                        <a href="#" @click.prevent="close">閉じる</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    `,
    data() {
        return {
            is_loading: false,
            html: ''
        }
    },
    props: {
        store_props: {
            type: Object,
            required: true
        }
    },
    computed: {
        title() {
            return this.store_props.title || 'ご利用ガイド';
        },
    },
    created() {
        this.getContent();
    },
    methods: {
        getContent() {
            this.is_loading = true;
            if (!this.store_props.API_URL) {
                console.log('APIのURLを確認してください');
                return;
            }
            axios.get(this.store_props.API_URL).then(res => {
                this.is_loading = false;
                if (res && res.status === 200 && res.data) {

                    // 規約が更新された場合
                    if (!isUndefined(res.data.term_text_update_flag)) {
                        // 規約再同意画面へ遷移
                        location.replace(res.data.re_agreement_url);
                        return;
                    }

                    this.html = res.data.html;
                    // 要素を埋め込んだら目的の要素までスクロール
                    this.$nextTick(() => {
                        if (!this.store_props.tgt_id) return;
                        const el = document.querySelector(this.store_props.tgt_id);
                        if (el) {
                            el.open = true;
                        }
                        setTimeout(smoothScroll(this.store_props.tgt_id), 500);
                    });
                }
            }).finally(e => {
                this.is_loading = false;
            });
        }
    },
    components:{
        Loading
    }
};
