import { createStore } from 'vuex'
import urls from '@/store/modules/urls'
import modal from '@/store/modules/modal'
import searchbox from '@/store/modules/searchbox'
import searchboxMenu from '@/store/modules/searchboxMenu'
import makeParamsModule from '@/store/modules/makeParamsModule'
import makeRealmConditionModule from "@/store/modules/makeRealmConditionModule";
import toSalesStatus from "@/store/modules/toSalesStatus"
import locale from "@/store/modules/locale"

const store = createStore({
    modules: {
        urls,
        modal,
        searchbox,
        searchboxMenu,
        mainParams:makeParamsModule(), // 今後複製パターンも出てくる可能性があるので念のため
        realmConditionsSearchbox:makeRealmConditionModule(),
        realmConditionsSearchboxTop:makeRealmConditionModule(),
        toSalesStatus,
        locale,
    },
})

export { store as default};
