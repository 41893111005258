/**
 * @typedef replace_from_to
 * @property {string} from 置換対象の文字列
 * @property {string} to 置換後の文字列を持つキー名
 */
/**
 * Laravel の route が出力した置換文字列を含むURLに置換処理をして戻す
 * @param {string} base_str
 * @param {replace_from_to[]} replace_str
 * @param {Object.<string,string>} params
 * @return {string}
 */
export const getStrParamsReplaced = ({base_str, replace_str=[],params = {}}) => {
    return replace_str.reduce( (ret_url, replace_item) => {
        const re = new RegExp(replace_item.from);
        const param_val = params[replace_item.to];
        return ret_url.replace(re, param_val);
    },base_str) || base_str;
}
