import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";

export const ModalRemittanceApplied = {
    name: "ModalRemittanceApplied",
    mixins: [modalDefaultMixin],
    emits: ['close'],
    template: `
    <div class="alert-inner">
        <p class="alert-msg" v-html="store_props.msg"></p>
        <div class="alert-btn">
            <p class="button" v-if="store_props.isPushNext"><a href="#" @click.prevent="hide">{{btn_label}}</a></p>
            <p class="button is-disabled" v-else>
                <a>{{btn_label}}</a>
            </p>
        </div>
    </div>
    `,
    props:{
        store_props:{
            type:Object,
            required:true
        },
    },
    computed:{
        btn_label(){
            return this.store_props.btn_label || 'OK';
        },
        btn_class_name(){
            return this.store_props.btn_class_name ?? '';
        }
    },
    methods:{
        hide(){
            this.close();
            if(this.store_props.close_callback && typeof this.store_props.close_callback === 'function'){
                this.store_props.close_callback();
            }
        }
    },
};
