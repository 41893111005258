export const Accordion = {
    name: "Accordion",
    template:`
        <details class="accordion" :open="initial_state">
            <summary>{{label}}</summary>
            <div><slot></slot></div>
        </details>
    `,
    props:{
        label:{
            type:String,
            required:true,
        },
        initial_state:{
            type:Boolean,
            default:false
        }
    }
};
