import {mapGetters} from 'vuex'
import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";
import {SearchConditions} from "@/components/SearchConditions";

export const ModalSearchConditions = {
    name: "ModalSearchConditions",
    template:`
      <div :class="type+'-inner'">
        <div :class="type+'-title'">
          <div :class="type+'-title-inner'">
            <h1>{{title}}</h1>
            <a href="#" class="modal-close" @click.prevent="closeModal"></a>
          </div>
        </div>
        <div :class="type+'-main'">
          <SearchConditions :realm_store_module_name="realm_store_module_name" :initial_values="initial_values" :fixed_values="fixed_values"></SearchConditions>
        </div>
      </div>
    `,
    mixins:[modalDefaultMixin],
    emits:['close'],
    components:{
        SearchConditions
    },
    props:{
        store_props:{
            type:Object,
            required:true
        }
    },
    computed:{
        ...mapGetters('searchbox', {state_of_searchbox: 'getState'}),
        /**
         * モーダルのタイトル
         * */
        title(){
            return this.store_props.title;
        },
        /**
         * 検索の初期値
         * */
        initial_values(){
            return this.store_props.initial_values;
        },
        /**
         * 検索の固定値
         * */
        fixed_values(){
            return this.store_props.fixed_values;
        },
        /**
         * 検索の初期値に固定値をマージした値
         * */
        values(){
            const ret = {...this.initial_values};
            for (let key in this.fixed_values) {
                ret[key] = this.fixed_values[key];
            }
            return ret;
        },
        realm_store_module_name(){
            return this.store_props.realm_store_module_name;
        },
    },
    methods:{
        closeModal(){
            // 条件を指定して検索でキーワードを変更していた場合に初期化したい
            this.$store.dispatch('searchbox/init', {...this.state_of_searchbox.old});
            this.close();
        }
    }
};
