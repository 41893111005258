import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";

export const ModalWithdrawNG = {
    name: "ModalWithdrawNG",
    mixins: [modalDefaultMixin],
    emits: ['close'],
    template: `
    <div class="modal-title">
        <div class="modal-title-inner">
            <h1>退会事前確認</h1>
            <a href="#" class="modal-close" @click.prevent="close">Close</a>
        </div>
    </div>
    <div class="modal-main">
        <div class="l-content860">
            <div class="headline headline-min">
                <h2>退会できません</h2>
            </div>
            <div class="l-box-primary">
                <p class="par">現在、以下の理由で退会できません。</p>
                <ul class="list list_min" v-for="(status_ng, index) in store_props.status_ngs" :key="index">
                    <li>{{messages[status_ng]}}</li>
                </ul>
                <div class="l-options">
                    <div class="l-back_btn_single">
                        <p class="button button-cancel">
                            <a href="#" @click.prevent="close">閉じる</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    data(){
        return {
            messages:{
                0: '出品している商品があります。',
                1: '入札している商品があります。',
                2: '未決済の落札商品があります。',
                3: '出金可能な売上金残高があります。'
            }
        }
    },
    props:{
        store_props:{
            type:Object,
            required:true
        },
    },
};
