export const ItemDescription = {
    name: "ItemDescription",
    template:`
        <div class="l-box-primary">
            <div class="item_meta_description">
                <p v-html="description"></p>
                <div v-if="can_seemore"><a href="#" @click.prevent="showAllDescription">もっと見る＞</a></div>
            </div>
        </div>
    `,
    data(){
        return {
            is_pressed_seemore:false,
        }
    },
    props:{
        item_description:{
            type:String,
            required:true
        },
        text_limit:{
            type:Number,
            default(){
                return 100;
            }
        },
    },
    computed:{
        /**
         * ボタン表示/非表示
         * @returns {Boolean}
         * */
        can_seemore(){
            return !this.is_pressed_seemore && this.item_description.length && this.item_description.length >= this.text_limit;
        },
        /**
         * メタデータのアイテム説明
         * @returns {String}
         * */
        description() {
            const description = this.item_description.replace(/\r\n|\n|\r/g, '<br>');
            return this.can_seemore
                ? description.substring(0, this.text_limit) + "\u{2026}"
                : description;
        },
    },
    methods: {
        showAllDescription(){
            this.is_pressed_seemore = true;
        },
    },
};
