import {isUndefined} from "./isUndefined.js";

/**
 * realmAttribute 関連の処理まとめたオブジェクト
 */
export const realmAttributeController = {
    /**
     * values で管理する name から realm_attribute の情報をオブジェクトの形にして返す
     * @param name
     * @return {{value_type: string, realm_id: string, value_key_name: string, trait_type: string}}
     */
    getObjByName(name){
        const [_ttl, realm_id, trait_type, value_type, value_key_name] = name.split('-');
        return {
            realm_id,
            trait_type,
            value_type,
            value_key_name
        }
    },
    /**
     * form_dataの形に加工して返す
     * @param realm_id
     * @param realm_attribute
     * @return {{name: string, err_msgs: {}}|{is_status: string[], name: string, list, err_msgs: {}}|{from: {name: string, type: string, err_msgs: {}}, to: {name: string, type: string, err_msgs: {}}}}
     */
    getFormData({realm_id, realm_attribute}){
        let form_data;
        if(realm_attribute.value_type*1 === 0){
            // ただの文字列パターン
            form_data = {
                name: 'attributes-'+realm_id+'-'+realm_attribute.trait_type+'-'+realm_attribute.value_type+'-string_value',
                err_msgs:{}
            };
            if(realm_attribute.string_length){
                form_data.maxlength = realm_attribute.string_length;
            }
        }else if(realm_attribute.value_type*1 === 1){
            // 数値範囲指定パターン
            form_data = {
                from: {
                    name: 'attributes-'+realm_id+'-'+realm_attribute.trait_type+'-'+realm_attribute.value_type+'-int_min_value',
                    type: 'number',
                    err_msgs:{}
                },
                to: {
                    name: 'attributes-'+realm_id+'-'+realm_attribute.trait_type+'-'+realm_attribute.value_type+'-int_max_value',
                    type: 'number',
                    err_msgs:{}
                }
            }
            if (!isUndefined(realm_attribute.int_min_value)) {
                form_data.from.min = realm_attribute.int_min_value;
                form_data.to.min = realm_attribute.int_min_value;
            }
            if (!isUndefined(realm_attribute.int_max_value)) {
                form_data.from.max = realm_attribute.int_max_value;
                form_data.to.max = realm_attribute.int_max_value;
            }
        }else if(realm_attribute.value_type*1 === 2){
            // 文字列選択式パターン
            form_data = {
                name: 'attributes-'+realm_id+'-'+realm_attribute.trait_type+'-'+realm_attribute.value_type+'-string_value',
                list:realm_attribute.option_value.map( option => {
                    return { label:option,value:option};
                }),
                is_status:['inline'],
                err_msgs:{}
            };
        }else if(realm_attribute.value_type*1 === 3){
            // 数値列選択式パターン
            form_data = {
                name: 'attributes-'+realm_id+'-'+realm_attribute.trait_type+'-'+realm_attribute.value_type+'-int_value',
                list:realm_attribute.option_value.map( option => {
                    return { label:option,value:option};
                }),
                is_status:['inline'],
                err_msgs:{}
            };
        }
        return form_data;
    },
    /**
     * form_parts の sub を返す
     * @param realm_attribute
     * @return {string[]}
     */
    getSubOfFormParts({realm_attribute}){
        const sub = [];
        if(realm_attribute.value_type*1 === 1){
            sub.push('min_max');
        }else if(realm_attribute.value_type*1 === 2 || realm_attribute.value_type*1 === 3){
            sub.push('radio');
        }else{
            sub.push('input');
        }
        return sub;
    },

    makeFormParts({realm_id, realm_attribute,idx = 0}){
        const form_data = this.getFormData({realm_id, realm_attribute})
        const sub = this.getSubOfFormParts({realm_attribute});
        return {
            ttl_label: realm_attribute.trait_type,
            name: 'attribute['+idx+']', // 一意のものにしておきたいので、 idx 差し込んでおく
            sub,
            is_status: ['ttl_size-mini'],
            form_data
        };
    }
};
