import {shallowRef} from "vue";
import {ModalAjaxError} from "@/components/ModalAjaxError";
import {ModalMsgAndLink} from "@/components/ModalMsgAndLink";
import {ModalAlert} from "@/components/ModalAlert";
import {ModalGuide} from "@/components/ModalGuide";
import {ModalBankRegister} from "@/components/ModalBankRegister";
import {ModalKycFeedback} from "@/components/ModalKycFeedback";
import {ModalRemittanceApplied} from "@/components/ModalRemittanceApplied";
import {ModalSaleItem} from "@/components/ModalSaleItem";
import {ModalSavedSearchToConfirmDelete} from "@/components/ModalSavedSearchToConfirmDelete";
import {ModalSimpleFeedback} from "@/components/ModalSimpleFeedback";
import {ModalItemInfo} from "@/components/ModalItemInfo";
import {ModalItemInfoForOnlyDisplay} from "@/components/ModalItemInfoForOnlyDisplay";
import {ModalSavedSearchSelectToDelete} from "@/components/ModalSavedSearchSelectToDelete";
import {ModalSavedSearchToSave} from "@/components/ModalSavedSearchToSave";
import {ModalSearchboxContents} from "@/components/ModalSearchboxContents"
import {ModalSearchConditions} from "@/components/ModalSearchConditions";
import {ModalSettingIcon} from "@/components/ModalSettingIcon";
import {ModalWithdrawOKWithAlert} from "@/components/ModalWithdrawOKWithAlert";
import {ModalWithdrawNG} from "@/components/ModalWithdrawNG";
/**
 * @typedef StateModal
 * @prop {boolean} is_show 表示すべしのときは true
 * @prop {string} component グローバルコンポーネントの名前。通常は Modal で始まる文字列
 * @prop {Object.<string,any>} store_props モーダル中身のコンポーネントに渡す情報
 * @type {string} type popup か fullpanelか
 * @type {string} tmpl_name モーダルにサブクラスとして適用 component名が入る
 * @type {boolean} is_closing_modal_component 現在 modal.vue がclose処理中かどうか
 * @type {SetStateModal[]} stack_set_state is_closing_modal_component が trueですぐに適用できないものの一時置き場
 */
/**
 * @type StateModal
 */
const state = {
    is_show:false,
    component:'',
    store_props:{},
    type:'',
    tmpl_name:'',
    is_closing_modal_component:false, // modal.vue 側がclosing のときは open を少し退避する必要がある
    stack_set_state:[] // resetStateする際にここに値があれば処理する
};

const getters = {
};

/**
 * 文字列をキャメルケースからスネークケースに変換
 * @param val {String} - 文字列
 * */
const camelToSnake = (val='')=> val.replace(/([A-Z])/g, (s)=> '_' + s.charAt(0).toLowerCase());
/**
 * @typedef SetStateModal モーダルオープンの際に渡すパラメータのオブジェクト
 * @prop {string} component グローバルコンポーネントの名前。通常は modal_ で始まる文字列
 * @prop {Object.<string,any>} [store_props = {}] モーダル中身のコンポーネントに渡す情報
 * @type {string} [type] popup か fullpanelかを固定で指定したい場合に。未指定の場合は常に responsiveController.getCurrentDevice から算出される
 */
/**
 * setState で渡すオブジェクトを生成。
 * @param {SetStateModal} payload
 * @return {SetStateModal}
 */
const makeSetStateObject = (payload) => {
    if(!payload.component){
        console.error('モーダルをオープンするのに必要な情報が不足', payload);
        return;
    }
    const ret = {};
    ret.is_show = true;
    ret.type = payload.type ? payload.type : 'modal';
    ret.store_props = payload.store_props || {};
    ret.component = shallowRef(payload.component);
    ret.tmpl_name = (camelToSnake(payload.component.name)).replace(/^_(.)/,"$1");//1文字目が_だったらトルツメ
    return ret;
}
const actions = {
    /**
     * payload で必要な情報を渡される素のopen
     * @param dispatch
     * @param {SetStateModal} payload
     */
    open({dispatch},payload={}){
        const setStateObject = makeSetStateObject(payload);
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * モーダルをオープンするアクション例
     * @param dispatch
     * @param title
     * @param lead
     */
    openBase({dispatch},{title,lead}){
        const setStateObject = makeSetStateObject({
            store_props:{title,lead},
            component:'modal_base'
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /* モーダルをオープンするアクションは必要な単位用意する */
    /**
     * ログインを促すモーダル
     * @param dispatch
     * @param lead {String} - メッセージ
     * @param login_url {String} - URL
     */
    openModalRequiresLogin({dispatch},{lead, login_url='#'}){
        dispatch('openModalMsgAndLink',{
            lead:lead || 'ログインが必要です',
            ok_btn_label:'ログインへ',
            LINK_URL: login_url,
            ModalMsgAndLink
        })
    },
    /**
     * コンポーネント modal_msg_and_link を開く
     * @param dispatch
     * @param {String} store_props.title - タイトル
     * @param {String} [store_props.lead] - メッセージ
     * @param {String} [store_props.close_btn_label] - 閉じるボタンのラベル
     * @param {String} [store_props.ok_btn_label] - OKボタンのラベル
     * @param {String} [store_props.LINK_URL] - URL
     * */
    openModalMsgAndLink({dispatch},{title,lead,close_btn_label,ok_btn_label,LINK_URL}){
        const setStateObject = makeSetStateObject({
            store_props:{
                title,
                lead,
                close_btn_label,
                ok_btn_label,
                LINK_URL
            },
            type:'alert',
            component:ModalMsgAndLink
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * コンポーネント ModalBankRegister を開く
     * @param dispatch
     * @param {String} store_props.bankName - 銀行名
     * @param {String} store_props.bankBranchName - 支店名
     * @param {String} store_props.bankAccountType -  銀行口座種別 普通 当座 貯蓄
     * @param {String} store_props.bankAccountNumber - 銀行口座番号
     * @param {String} store_props.familyNameJaKanaJP - カナ姓
     * @param {String} store_props.givenNameJaKanaJP - カナ名
     * @param {Boolean} store_props.isPushBack - 戻るを押せるかどうか
     * @param {Boolean} store_props.isPushNext - 次へを押せるかどうか
     * @param {Boolean} store_props.isNewRegistration - 新規登録かどうか（登録/変更ボタン切替）
     * @param {Function} store_props.close_callback
     * */
    openModalBankRegister({dispatch}, {bankName,bankBranchName,bankAccountType,bankAccountNumber,familyNameJaKanaJP,givenNameJaKanaJP,isPushBack,isPushNext,isNewRegistration,close_callback}) {
        const setStateObject = makeSetStateObject({
            store_props: {
                bankName,
                bankBranchName,
                bankAccountType,
                bankAccountNumber,
                familyNameJaKanaJP,
                givenNameJaKanaJP,
                isPushBack,
                isPushNext,
                isNewRegistration,
                close_callback
            },
            component:ModalBankRegister
        });
        if (setStateObject) {
            dispatch('setStateForOpen', setStateObject);
        }
    },
    /**
     * コンポーネント modal_saved_search_select_to_delete を開く
     * @param dispatch
     * @param delete_api_url {String}
     * @param saved_searches {Array}
     * @param close_callback {Function}
     * @param component {Object} - Vue component
     */
    openModalSavedSearchSelectToDelete({dispatch},{delete_api_url,saved_searches,close_callback}){
        const setStateObject = makeSetStateObject({
            store_props:{delete_api_url,saved_searches,close_callback},
            component:ModalSavedSearchSelectToDelete
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * コンポーネント modal_saved_search_to_confirm_delete を開く
     * @param dispatch
     * @param {String} store_props.lead - 見出し文
     * @param {String} store_props.close_btn_label - 閉じるボタンのラベル
     * @param {String} store_props.delete_btn_label - 削除ボタンのラベル
     * @param {Function} [store_props.close_callback]
     */
    openModalSavedSearchToConfirmDelete({dispatch},{lead, close_btn_label, delete_btn_label, close_callback}){
        const setStateObject = makeSetStateObject({
            type: 'alert',
            store_props:{
                lead,
                close_btn_label,
                delete_btn_label,
                close_callback
            },
            component:ModalSavedSearchToConfirmDelete
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * コンポーネント modal_saved_search_to_save を開く
     * @param dispatch
     * @param store_props.saved_search
     * @param store_props.update_api_url
     * @param store_props.max_label
     * @param store_props.mode
     * @param {object} ajax_error_messages - Ajaxでエラーになった場合のメッセージ
     * @param {object} component - Vue component
     */
    openModalSavedSearchToSave({dispatch}, {saved_search, mode, max_label, update_api_url, ajax_error_messages}) {
        const setStateObject = makeSetStateObject({
            store_props: {
                saved_search,
                mode,
                max_label,
                update_api_url,
                ajax_error_messages,
            },
            component:ModalSavedSearchToSave
        });
        if (setStateObject) {
            dispatch('setStateForOpen', setStateObject);
        }
    },
    /**
     * コンポーネント ModalSearchboxContents を開く
     * @param dispatch
     */
    openModalSearchboxContents({dispatch}){
        const setStateObject = makeSetStateObject({
            component: ModalSearchboxContents
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * コンポーネント modal_sale_item を開く
     * @param dispatch
     * @param {Object} sales_list - 振込情報
     * @param {String} status_labels - ステータス
     * */
    openModalSaleItem({dispatch},{sales_list, status_labels}){
        const setStateObject = makeSetStateObject({
            store_props:{
                sales_list,
                status_labels
            },
            component:ModalSaleItem
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * コンポーネント modal_item_info を開く
     * @param dispatch
     * @param {Object} [store_props.item] - アイテム情報
     * @param {String} [store_props.item_owner] - アイテム所有者
     * @param {Array} [store_props.exhibit_status_list] - 販売可能かステータス一覧
     * @param {Array} [store_props.disabled_status_list] - 無効な販売ステータス一覧
     * @param {Object} [store_props.api_urls] - API URL
     * @param {Object} [store_props.urls] - URL
     * @param {Object} [store_props.user_display_message] - ユーザエラーの内容
     * @param {Object} [store_props.btn_label] - ボタンのラベル
     * @param {String} [store_props.mode] - 表示内容を変更するための変数
     * @param {Object} [store_props.ajax_error_messages] - ajaxエラーメッセージ
     * @param {Function} [store_props.close_callback]
     */
    openModalItemInfo({dispatch}, {item,item_owner,exhibit_status_list=[],disabled_status_list=[],api_urls,urls,user_display_message,btn_label,mode,ajax_error_messages,close_callback}){
        const setStateObject = makeSetStateObject({
            store_props:{
                item,
                item_owner,
                exhibit_status_list,
                disabled_status_list,
                api_urls,
                urls,
                user_display_message,
                btn_label,
                mode,
                ajax_error_messages,
                close_callback,
            },
            component: ModalItemInfo,
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * コンポーネント modal_item_info（ユーザ別所持アイテム一覧向け） を開く
     * @param dispatch
     * @param {Object} store_props
     * @param {Object} store_props.item       - 商品情報
     * @param {String} store_props.item_owner - 現在の所有者
     * @param {String} store_props.ajax_error_messages - Ajaxエラーの際に表示するメッセージ
     * @param {Object} component - Vue component
     */
    openModalItemInfoForSearchUser({dispatch}, {item, item_owner, ajax_error_messages}){
        const setStateObject = makeSetStateObject({
            store_props:{
                item,
                item_owner,
                ajax_error_messages
            },
            component:ModalItemInfoForOnlyDisplay
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },

    /**
     * コンポーネント modal_search_conditions を開く
     * @param dispatch
     * @param {String} store_props.title       - タイトル
     * @param {Object} store_props.initial_values - 初期値
     * @param {String} store_props.realm_store_module_name
     * @param {Boolean} store_props.fixed_values - 固定値
     * @param {String} store_props.updateTmpValues
     */
    openModalSearchConditions({dispatch},{title, initial_values, realm_store_module_name, fixed_values= {}, updateTmpValues}){
        const setStateObject = makeSetStateObject({
            store_props: {
                title,
                initial_values,
                realm_store_module_name,
                fixed_values,
                updateTmpValues,
            },
            component: ModalSearchConditions
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * コンポーネント modal_simple_feedback を開く
     * @param dispatch
     * @param {String} [store_props.lead] - リード文
     * @param {String} [store_props.link_url] - OKボタンクリック後、閉じるだけでなくリンクしたい場合は指定
     * @param {String} []store_props.LS_KEY] - モーダル表示維持用のローカルストレージのキーを格納
     * @param {Object} component - Vueコンポーネント
     */
    openModalSimpleFeedback({dispatch},{lead, LS_KEY, link_url}){
        const setStateObject = makeSetStateObject({
            type: 'alert',
            store_props:{
                lead,
                LS_KEY,
                link_url
            },
            component:ModalSimpleFeedback
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },

    /**
     * コンポーネント modal_remittance_applied を開く
     * @param {String} store_props.msg - 表示するメッセージ
     * @param {String} [store_props.btn_label] - ボタンのラベル
     * @param {String} [store_props.btn_class_name] - ボタンのクラス
     * @param {Boolean} store_props.isPushNext - 次へを押せるかどうか
     * @param {Function} [store_props.close_callback]
     * */
    openModalRemittanceApplied({dispatch},{msg, btn_label, btn_class_name, isPushNext, close_callback}){
        const setStateObject = makeSetStateObject({
            store_props:{
                msg,
                btn_label,
                btn_class_name,
                isPushNext,
                close_callback
            },
            type:'alert',
            component:ModalRemittanceApplied
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * コンポーネント modal_guide を開く
     * @param {String} [store_props.title] - タイトル
     * @param {String} [store_props.API_URL] - API URL
     * @param {String} [store_props.tgt_id]  - スクロールするための目的の要素
     * */
    openModalGuide({dispatch},{title, API_URL, tgt_id,}){
        const setStateObject = makeSetStateObject({
            store_props: {
                title,
                API_URL,
                tgt_id
            },
            type:'modal',
            component:ModalGuide
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },

    /**
     * store側からモーダルをクローズする。
     * 内部的には is_show を falseにするのみ
     * @param state
     * @param commit
     */
    closeFromStore({state,commit}){
        if(state.is_show && !state.is_closing_modal_component) {
            // is_show のみ false に。 modal.vue から resetStateAfterModalClosed へ通知がくるのを期待
            commit('setState', {is_show:false});
        }
    },

    /**
     * モーダルオープンのための情報を受け取って、
     * オープンできる状態であれば setState に渡す
     * オープンできない状態なら stack_set_state に退避
     * @param state
     * @param commit
     * @param {SetStateModal} setStateObject
     */
    setStateForOpen({state,commit},setStateObject){
        if(state.is_closing_modal_component) {
            // モーダルクロージング中は退避
            state.stack_set_state.push(setStateObject);
        }else if(state.component){
            // 現在別のものがオープンしている場合
            // 退避して
            state.stack_set_state.push(setStateObject);
            // is_show のみ false に。 modal.vue から resetStateAfterModalClosed へ通知がくるのを期待
            commit('setState', {is_show:false});
        }else{
            // 普通にオープンできるときはそのまま渡す
            commit('setState',setStateObject);
        }
    },

    /**
     * モーダルクローズ終了の通知を modal.vue から受け取る
     * stateを初期化して、stackがあれば処理
     * @param state
     * @param commit
     */
    resetStateAfterModalClosed({state,commit}){
        console.log('store.modal:resetStateAfterModalClosed');
        // stack_set_state があれば、一番最後のものだけ処理するようでとっておく
        const payload_for_set_state = state.stack_set_state.length ? state.stack_set_state.pop() : null;
        // 初期化
        commit('resetState');
        // 退避していたものがあれば処理
        if(payload_for_set_state){
            commit('setState',payload_for_set_state);
        }
    },
    /**
     * コンポーネント modal_alert を開く
     * @param dispatch
     * @param {String} store_props.msg - 表示するメッセージ
     * @param {String} [store_props.btn_label] - ボタンのラベル
     * @param {String} [store_props.btn_class_name] - ボタンのクラス
     * @param {Function} [store_props.close_callback]
     * */
    openModalAlert({dispatch}, {msg, btn_label, btn_class_name, close_callback}){
        const setStateObject = makeSetStateObject({
            store_props:{
                msg,
                btn_label,
                btn_class_name,
                close_callback
            },
            type:'alert',
            component:ModalAlert
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * コンポーネント KycFeedback を開く
     * @param dispatch
     * @param {String} store_props.msg - 表示するメッセージ
     * @param {String} [store_props.btn_label] - ボタンのラベル
     * @param {String} [store_props.btn_class_name] - ボタンのクラス
     * @param {Function} [store_props.close_callback]
     * */
    openModalKycFeedback({dispatch},{msg, btn_label, btn_class_name, close_callback}){
        const setStateObject = makeSetStateObject({
            store_props:{
                msg,
                btn_label,
                btn_class_name,
                close_callback
            },
            type:'alert',
            component:ModalKycFeedback
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * コンポーネント プロフィールアイコン設定 を開く
     * @param dispatch
     * @param {Object} [store_props.item] - アイテム情報
     * @param {String} [store_props.default_image_url] - デフォルト画像のURL
     * @param {String} [store_props.next_url] - 次へのURL
     * */
    openModalSettingIcon({dispatch},{item, default_image_url, next_url,}){
        const setStateObject = makeSetStateObject({
            close_only_by_btns:true,
            store_props:{
                item,
                default_image_url,
                next_url,
            },
            type:'modal',
            component:ModalSettingIcon
        });
        if(setStateObject){
            dispatch('setStateForOpen',setStateObject);
        }
    },
    /**
     * Ajaxで200以外のHTTPレスポンスを受け取った場合エラーモーダルを表示したい
     * @param dispatch
     * @param {Object} [store_props.status] - エラーステータス
     * @param {Object} [store_props.errors] - エラー内容
     * @param {Object} store_props.messages - エラー内容にメッセージがなかったら表示するメッセージ
     */
    openModalAjaxError({dispatch}, {status,errors,messages}) {
        const setStateObject = makeSetStateObject({
            store_props: {
                status,
                errors,
                messages
            },
            type: 'alert',
            component: ModalAjaxError
        });
        if (setStateObject) {
            dispatch('setStateForOpen', setStateObject);
        }
    },

    /**
     * お問い合わせ（退会申請）表示の前に注意喚起
     * @param dispatch
     * @param {String} store_props.url
     */
    openModalWithdrawOKWithAlert({dispatch},{url}) {
        const setStateObject = makeSetStateObject({
            store_props: {
                url
            },
            type: 'modal',
            component: ModalWithdrawOKWithAlert
        });
        if (setStateObject) {
            dispatch('setStateForOpen', setStateObject);
        }
    },

    /**
     * 退会申請が不可の場合に表示
     * @param dispatch
     * @param {Object} store_props.status_ngs
     */
    openModalWithdrawNG({dispatch}, {status_ngs}){
        const setStateObject = makeSetStateObject({
            store_props: {
                status_ngs
            },
            type: 'modal',
            component: ModalWithdrawNG
        });
        if (setStateObject) {
            dispatch('setStateForOpen', setStateObject);
        }
    }
};

const mutations = {
    /**
     * state の値を更新
     * （すべて更新できてしまうので、外からはできるだけ呼ばない）
     * @param state
     * @param payload
     */
    setState(state, payload){
        for(let key in payload){
            if(state[key] !== void 0){
                state[key] = payload[key];
                // Vue.set(state, key, payload[key]);
            }
        }
    },

    /**
     * state.typeの更新
     * @param state
     * @param type
     */
    setType(state,type){
        state.type = type;
    },

    /**
     * is_closing_modal_component を trueにする
     * @param state
     */
    isClosing(state){
        state.is_closing_modal_component = true;
    },

    /**
     * state の値を初期化
     * @param state
     */
    resetState(state){
        const initial_state = {
            is_show:false,
            component:'',
            store_props:{},
            type:'',
            name:'',
            tmpl_name:'',
            is_closing_modal_component:false,
            stack_set_state:[]
        };
        for(let key in initial_state){
            if(state[key] !== void 0){
                state[key] = initial_state[key];
                // Vue.set(state, key, initial_state[key]);
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
