export const FormRadio = {
    name: "FormRadio",
    emits:['update-val'],
    template:`
    <div class="l-form-col">
        <div class="l-form-col-item" :class="classes" v-for="radio in radios">
            <div class="form_radio">
                <label :for="form_data.name +'_'+radio.value">
                    <input
                        type="radio"
                        :id="form_data.name +'_'+radio.value"
                        :name="name" v-model="value"
                        :value="radio.value"
                        :disabled="form_data.disabled || radio.disabled">
                    <span> {{radio.label}}</span>
                </label>
            </div>
        </div>
    </div>
    `,
    props:{
        form_data:{
            type:Object,
            required:true
        },
        values:{
            type:Object,
            required:true
        },
        only_disp_value:{
            type:[String,Number]
        }
    },
    computed:{
        value:{
            get(){
                return this.values[this.form_data.name];
            },
            set(val){
                this.$emit('update-val', {name:this.form_data.name,val});
            }
        },
        name(){
            return this.form_data.name || '';
        },
        radios(){
            return this.form_data.list;
        },
        classes(){
            const sub = this.form_data.sub || [];
            const is_status = this.form_data.is_status || [];
            return sub.map( sub_str => 'form_radio-'+sub_str).concat(is_status.map( is_str => 'is-'+is_str));
        },
    },
};
