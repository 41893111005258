import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";
import {ItemTradeHistories} from "@/components/ItemTradeHistories";
import {BtnItemAttributes} from "@/components/BtnItemAttributes";
import {ItemAttributes} from "@/components/ItemAttributes";
import {ItemDescription} from "@/components/ItemDescription";
import {Accordion} from "@/components/Accordion";

export const ModalItemInfoForOnlyDisplay = {
    name: "ModalItemInfoForOnlyDisplay",
    template:`
        <div :class="type+'-inner'">
            <div :class="type+'-title'">
                <div class="modal-title-inner">
                    <h1>アイテム情報</h1><a href="#" class="modal-close" @click.prevent="close">Close</a>
                </div>
            </div>
            <div :class="type+'-main'">
                <div class="l-item-content">
                    <div class="l-item_detail_image">
                        <p class="item_detail_image" :class="{'is-pixelated':is_pixelated}">
                            <img :src="image_url" alt="" width="128" height="128">
                        </p>
                    </div>
                    <div class="l-item_detail">
                        <div class="item_detail">
                            <dl>
                                <dt>{{ item.name }}</dt>
                                <dd class="item_detail-no" v-if="item.token_index && item.show_lotno">Lot No.{{ item.token_index }}</dd>
                            </dl>
                        </div>
                        <div class="l-box-primary">
                            <div class="item_param">
                                <p v-if="item.free_text" class="item_param-lead l-pre_wrap">{{ item.free_text }}</p>
                                <div v-if="item.realm_name" class="item_param-list">
                                    <dl>
                                        <dt>カテゴリ</dt>
                                        <dd>{{ item.realm_name }}</dd>
                                    </dl>
                                </div>
                            </div>
                            <ItemAttributes :item_attributes="item_attributes" :is_attributes="(item_id === '')"></ItemAttributes>
                            <div class="item_param" v-if="item.external_url">
                                <div class="item_param-detail_btn">
                                    <BtnItemAttributes :url="item.external_url"></BtnItemAttributes>
                                </div>
                            </div>
                        </div>
                        <ItemDescription v-if="item.description" :item_description="item.description"></ItemDescription>
                        <div class="l-box-primary">
                            <div class="item_info">
                                <dl>
                                    <dt>現在の所有者</dt>
                                    <dd v-if="item_owner===null">退会したユーザー</dd>
                                    <dd v-else>{{item_owner}}</dd>
                                </dl>
                                <dl>
                                    <dt>対象タイトル</dt>
                                    <dd><a :href="search_service_url">{{service_name}}</a></dd>
                                </dl>
                            </div>
                        </div>
                        <div class="l-section" v-if="is_trade_histories_display">
                            <Accordion id="trade_histories" label="取引履歴" :is_show="is_accordion_display">
                                <ItemTradeHistories @display="switchDisplayTradeHistories" :item="item" :ajax_error_message="store_props.ajax_error_messages.communication"></ItemTradeHistories>
                            </Accordion>
                        </div>
                        <div class="l-btn_single">
                            <p class="button button-cancel">
                                <a href="#" @click.prevent="close">閉じる</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    data() {
        return {
            is_trade_histories_display: true,
            is_accordion_display: false,
        };
    },
    props: {
        store_props: {
            type: Object,
            required: true,
        }
    },
    mixins: [modalDefaultMixin],
    emits:['close'],
    components:{
        ItemTradeHistories,
        BtnItemAttributes,
        ItemAttributes,
        ItemDescription,
        Accordion,
    },
    computed: {
        item() {
            return this.store_props.item;
        },
        item_id(){
            return this.store_props.item.id || '';
        },
        item_attributes () {
            return this.store_props.item.attributes;
        },
        /**
         * 商品画像のimage-rendering対応
         * @returns {Boolean}
         * */
        is_pixelated(){
            return !! this.item.thumbnail_pixel_url;
        },
        /**
         * 商品画像のURL
         * @returns {String}
         * */
        image_url(){
            if(this.is_pixelated){
                return this.item.thumbnail_pixel_url;
            }
            return this.$store.getters['urls/getUrlOf']({key:'IMAGE',params:this.item});
        },
        item_owner() {
            return this.store_props.item_owner;
        },
        service_name() {
            return this.store_props.item.service_name;
        },
        search_service_url() {
            console.log(this.store_props);
            return this.$store.getters['urls/getUrlOf']({key:'SERVICE_ITEMS',params:{service_id:this.store_props.item.service_id}});
        },
    },
    methods: {
        switchDisplayTradeHistories(data){
            this.is_accordion_display = !! data;
            this.is_trade_histories_display =  !! data;
        },
    }
};
