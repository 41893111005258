export const FormView = {
    name: "FormView",
    template:`
        <div class="form_view" :class="classes">
        {{value_d}}
        <input type="hidden" :name="name" :value="value" /><!-- valueが配列系のフォローがされてないので必要であればお願いします -->
    </div>
    `,
    props:{
        form_data:{
            type:Object,
            required:true
        },
        values:{
            type:Object,
            required:true
        },
        add_is_status:{
            type:Array,
            default(){
                return [];
            }
        },
    },
    computed:{
        name(){
            return this.form_data.name || '';
        },
        classes(){
            const sub = this.form_data.sub || [];
            const is_status = this.form_data.is_status || [];
            return sub.map( sub_str => 'form_input-'+sub_str).concat(is_status.map( is_str => 'is-'+is_str),this.add_is_status.map( is_str => 'is-'+is_str));
        },
        value(){
            return this.values[this.form_data.name] || '';
        },
        value_d(){
            // ここ、他に必要な制御があれば追加してください。
            // 改行対応とかいる気がします。
            let ret = '';
            if(this.form_data.list !== void 0 && Array.isArray(this.form_data.list)){
                ret = this.form_data.list.reduce( (ret, option) => {
                    if(option.value && option.value == this.value){
                        ret = option.label;
                    }
                    return ret;
                },'');
                if(!ret){
                    ret = '未選択'
                }
            }else{
                ret = this.value || '未入力'
            }
            return ret;
        }
    }
};
