import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";

export const ModalMsgAndLink = {
    name: "ModalMsgAndLink",
    mixins:[modalDefaultMixin],
    emits:['close'],
    template:`
    <div v-if="type==='alert'" class="alert-inner">
        <p class="alert-msg" v-html="lead"></p>
        <div class="alert-btn_area">
            <div class="alert-btn"><p class="button button-cancel "><a href="#" @click.prevent="close">{{close_btn_label}}</a></p></div>
            <div class="alert-btn"><p class="button"><a href="#" @click.prevent="onClickOkBtn">{{ok_btn_label}}</a></p></div>
        </div>
    </div>
    <div v-else :class="type+'-inner'">
        <div :class="type+'-title'">
            <div :class="type+'-title-inner'">
                <h1>{{title}}</h1>
                <a href="#" class="modal-close" @click.prevent="close"></a>
            </div>
        </div>
        <div :class="type+'-main'">
            <p v-html="lead"></p>
            <div class="l-two_choices">
                <div class="l-two_choices-back"><p class="button button-cancel"><a href="#" @click.prevent="close">{{close_btn_label}}</a></p></div>
                <div class="l-two_choices-next"><p class="button"><a href="#" @click.prevent="onClickOkBtn">{{ok_btn_label}}</a></p></div>
            </div>
        </div>
    </div>
    `,
    data(){
        return {}
    },
    props:{
        store_props:{
            type:Object,
            required:true
        }
    },
    computed:{
        title(){
            return this.store_props.title||'確認';
        },
        lead(){
            return this.store_props.lead || '';
        },
        close_btn_label(){
            return this.store_props.close_btn_label || 'キャンセル';
        },
        ok_btn_label(){
            return this.store_props.ok_btn_label || 'OK';
        }
    },
    methods:{
        onClickOkBtn(){
            location.href = this.store_props.LINK_URL;
        }
    }
};
