import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";
import {Loading} from "@/components/Loading";
import {ItemTradeHistories} from "@/components/ItemTradeHistories";
import {ItemExternalLink} from "@/components/ItemExternalLink";
import {ItemAttributes} from "@/components/ItemAttributes";
import {ItemDescription} from "@/components/ItemDescription";
import {Accordion} from "@/components/Accordion";
import {isUndefined} from '@/lib/isUndefined.js';

export const ModalItemInfo = {
    name: "ModalItemInfo",
    template:`
      <div :class="type+'-inner'">
        <div :class="type+'-title'">
          <div class="modal-title-inner">
            <h1>アイテム情報</h1><a href="#" class="modal-close" @click.prevent="close">Close</a>
          </div>
        </div>
        <div :class="type+'-main'">
          <div class="l-item-content">
            <div class="l-item_detail_image">
              <p class="item_detail_image" :class="{'is-pixelated':is_pixelated}">
                <img :src="image_url" alt="" width="128" height="128">
              </p>
            </div>
            <div class="l-item_detail">
              <div class="item_detail">
                <dl>
                  <dt>{{ item.name }}</dt>
                  <dd class="item_detail-no" v-if="item.token_index && item.show_lotno">Lot No.{{ item.token_index }}</dd>
                </dl>
              </div>
              <div class="l-box-primary">
                <loading v-if="is_loading.item" :is_show="is_loading.item" sub="min"></loading>
                <template v-else>
                  <div class="item_param">
                    <p v-if="item.free_text" class="item_param-lead l-pre_wrap">{{ item.free_text }}</p>
                    <div v-if="item.realm_name" class="item_param-list">
                      <dl>
                        <dt>カテゴリ</dt>
                        <dd>{{ item.realm_name }}</dd>
                      </dl>
                    </div>
                  </div>
                  <ItemAttributes :item_attributes="item_attributes" :is_attributes="(item_id === '')"></ItemAttributes>
                  <ItemExternalLink v-if="item.external_url" :external_url="item.external_url"></ItemExternalLink><!-- パラメータ詳細 -->
                </template>
              </div>
              <ItemDescription v-if="item.description" :item_description="item.description"></ItemDescription>
              <div class="l-box-primary">
                <div class="item_info">
                  <dl>
                    <dt>現在の所有者</dt>
                    <dd>
                      <div class="item_info-user" v-html="item_owner"></div><!--MEMO: item_ownerはエスケープ済みの名前-->
                    </dd>
                  </dl>
                  <dl>
                    <dt>対象タイトル</dt>
                    <dd>
                      <span><a :href="search_service_url">{{ service_name }}</a></span>
                    </dd>
                  </dl>
                </div>
              </div>
              <div class="l-section" v-if="is_trade_histories_display">
                <Accordion id="trade_histories" label="取引履歴" :is_show="is_accordion_display">
                  <ItemTradeHistories @display="switchDisplayTradeHistories" :item="item" :ajax_error_message="store_props.ajax_error_messages.communication"></ItemTradeHistories>
                </Accordion>
              </div>
              <template v-if="mode==='my_nft'">
                <p class="error_par" v-if='user_display_message'>{{ user_display_message }}</p>
                <div class="l-two_choices">
                  <div class="l-two_choices-back">
                    <p class="button button-cancel">
                      <a href="#" @click.prevent="close">キャンセル</a>
                    </p>
                  </div>
                  <div class="l-two_choices-next">
                    <p class="button is-disabled" v-if="is_loading.item||is_disabled">
                      <a>{{ display_button_label }}</a>
                    </p>
                    <p class="button" v-else>
                      <a v-if="next_url==='#'" :href="next_url" @click.prevent="gotoNextPage">{{ display_button_label }}</a>
                      <a v-else :href="next_url" >{{ display_button_label }}</a>
                    </p>
                  </div>
                </div>
              </template>
              <div class="l-btn_single" v-else>
                <p class="button button-cancel">
                  <a href="#" @click.prevent="close">閉じる</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    `,
    components: {
        Loading,
        ItemTradeHistories,
        ItemExternalLink,
        ItemAttributes,
        ItemDescription,
        Accordion
    },
    data() {
        return {
            trade_histories: [],
            user_display_status: '',
            is_loading: {
                item: false,
                trade_histories: false
            },
            accordion_val: false,
            CSRF_TOKEN: null,
            is_trade_histories_display: true,
            is_accordion_display: false,
        }
    },
    props: {
        store_props: {
            type: Object,
            required: true
        }
    },
    mixins: [modalDefaultMixin],
    emits:['close'],
    computed: {
        item_id() {
            return this.store_props.item.id || '';
        },
        item() {
            return this.store_props.item;
        },
        item_attributes() {
            return this.store_props.item.attributes;
        },
        service_name() {
            console.log(this.store_props);
            return this.store_props.item.service_name;
        },
        search_service_url() {
            console.log(this.store_props);
            return this.$store.getters['urls/getUrlOf']({key:'SERVICE_ITEMS',params:{service_id:this.store_props.item.service_id}});
        },
        /**
         * 商品画像のimage-rendering対応
         * @returns {Boolean}
         * */
        is_pixelated(){
            return !! this.store_props.item.thumbnail_pixel_url;
        },
        /**
         * 商品画像のURL
         * @returns {String}
         * */
        image_url() {
            if (this.is_pixelated) {
                return this.store_props.item.thumbnail_pixel_url;
            }
            return this.$store.getters['urls/getUrlOf']({key: 'IMAGE', params: this.item});
        },
        next_url() {
            if (this.store_props.exhibit_status_list.includes(this.user_display_status)) {
                return this.$store.getters['urls/getUrlOf']({key:'EXHIBIT_INPUT',params:this.item});
            }
            return this.store_props.urls[this.user_display_status] || '';
        },
        item_owner() {
            return this.store_props.item_owner ?? '-'; // 名前はあるはずだけど一応
        },
        /**
         * メタデータ取得失敗（未出品時のみ）
         * @return {Boolean}
         * */
        is_no_metadata(){
            return this.item.description === null && !this.item.attributes.length;
        },
        /**
         * OKボタンを無効にするか
         * */
        is_disabled() {
            // user_display_statusがボタンを無効にするステータス一覧にあるかチェック、またはメタデータ取得失敗の場合
            return this.store_props.disabled_status_list.includes(this.user_display_status) || !this.user_display_message && this.is_no_metadata;
        },
        /**
         * ユーザーへのエラーメッセージ
         * @param {String}
         * */
        user_display_message() {
            return this.store_props.user_display_message[this.user_display_status] || '';
        },
        /**
         * OKボタンのラベル
         * @param {String}
         * */
        display_button_label() {
            return this.store_props.btn_label[this.user_display_status] || '出品登録へ';
        },
        mode(){
            return this.store_props.mode;
        },
        close_callback(){
            if(isUndefined(this.store_props.close_callback)) {
                return undefined;
            }else{
                return this.store_props.close_callback[this.user_display_status];
            }
        }
    },
    created() {
        this.getItemData();
    },
    methods: {
        moveToInputExhibit() {
            vm_page_loading.show();
            location.href = this.next_url;
        },
        /*
        * アイテム情報の取得
        * **/
        getItemData() {
            this.is_loading.item = true;
            axios.get(this.store_props.api_urls.nft_show, {
                params: {
                    token_realm_id: this.item.token_realm_id,
                    token_short_kind: this.item.token_short_kind,
                    token_index: this.item.token_index,
                },
            }).then(res => {
                this.is_loading.item = false;
                if (res.status !== 200 || res.data.errors) {
                    this.openModalAjaxError(res);
                    return;
                }

                // 規約が更新された場合
                if (!isUndefined(res.data.term_text_update_flag)) {
                    // 規約再同意画面へ遷移
                    location.replace(res.data.re_agreement_url);
                    return;
                }

                this.user_display_status = res.data.user_status;
            }).catch((err) => {
                this.openModalAjaxError(err);
            });
        },
        openModalAjaxError(res){
            this.$store.dispatch('modal/openModalAjaxError',{
                status: res.status,
                errors: res.data.errors,
                messages: this.store_props.ajax_error_messages,
            });
        },
        switchDisplayTradeHistories(data){
            this.is_accordion_display = !! data;
            this.is_trade_histories_display =  !! data;
        },
        gotoNextPage(){
            this.close();
            if(this.close_callback && typeof this.close_callback === 'function'){
                console.log('close_callback');
                this.close_callback({
                    user_display_status : this.user_display_status,
                    token_realm_id: this.item.token_realm_id,
                    token_short_kind: this.item.token_short_kind,
                    token_index: this.item.token_index,
                });
            }
        },
    },
};
