export const FormInput= {
    name: "FormInput",
    template:`
     <div class="form_input" :class="classes">
        <input :type="type" :name="name" :min="form_data.min" :max="form_data.max" :placeholder="placeholder" :disabled="form_data.disabled" v-model="value">
    </div>
    `,
    emits:['update-val'],
    props:{
        form_data:{
            type:Object,
            required:true
        },
        values:{
            type:Object,
            required:true
        },
        add_is_status:{
            type:Array,
            default(){
                return [];
            }
        },
    },
    computed:{
        type(){
            return this.form_data.type || 'text'
        },
        name(){
            return this.form_data.name || '';
        },
        placeholder(){
            let ret = '';
            if(this.form_data.placeholder !== void 0){
                ret = this.form_data.placeholder;
            }else if(this.type === 'text'){
                ret = '入力してください';
            } else if(this.type==='number' && (this.name === 'price_min' || new RegExp(/min_value/).test(this.name))){
                ret = this.form_data.min;
            } else if(this.type==='number' && (this.name === 'price_max' || new RegExp(/max_value/).test(this.name))){
                ret = this.form_data.max;
            }
            return ret;
        },
        classes(){
            const sub = this.form_data.sub || [];
            const is_status = this.form_data.is_status || [];
            return sub.map( sub_str => 'form_input-'+sub_str).concat(is_status.map( is_str => 'is-'+is_str),this.add_is_status.map( is_str => 'is-'+is_str));
        },
        value:{
            get(){
                return this.values[this.form_data.name] || '';
            },
            set(val){
                this.$emit('update-val', {name:this.form_data.name,val});
            }
        }
    }
};
