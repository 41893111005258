export const BaseButton= {
    name:'BaseButton',
    emits:['click-btn'],
    template:`
        <p class="button" :class="classes">
            <a v-if="isDisabled||isLoading"><slot></slot></a>
            <a v-else-if="url && isExternal" :href="url" target="_blank"><slot></slot></a>
            <a v-else-if="url" :href="url"><slot></slot></a>
            <a v-else href="#" @click.prevent="$emit('click-btn')"><slot></slot></a>
        </p>`,
    props:{
        addClasses:{
          type:Array,
          default(){
              return [];
          }
        },
        isDisabled:{
            type:Boolean,
            default:false
        },
        isExternal:{
            type:Boolean,
            default:false
        },
        isLoading:{
            type:Boolean,
            default:false
        },
        url:{
            type:String,
            default: ''
        }
    },
    computed:{
        classes(){
            let ret = {
                'is-disabled': this.isDisabled,
                'is-external': this.isExternal,
                'is-loading': this.isLoading
            };
            if (this.addClasses.length) {
                this.addClasses.forEach(addClass => {
                    ret[addClass] = true;
                });
            }
            return ret;
        }
    },

}
