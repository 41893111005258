import ApiBase from './ApiBase'

const filter = {
    use_cache: true,
    api_request_type:'get',
    params: [
        {
            name: 'callback',
            required: true
        }
    ],
}

const itemParam = new ApiBase(filter);

export { itemParam as default};
