import {mapState,mapGetters} from 'vuex'
import {modalDefaultMixin} from "@/mixins/modalDefaultMixin";
import {Loading} from "@/components/Loading";

export const ModalSearchboxContents= {
    name:'ModalSearchboxContents',
    mixins:[modalDefaultMixin],
    emits:['close'],
    template:`
     <div class="modal-inner">
        <div class="modal-title">
            <div class="modal-title-inner">
                <h1>タイトルを指定して検索</h1>
                <a href="#" class="modal-close" @click.prevent="close">Close</a>
            </div>
        </div>
        <div class="modal-main">
            <div class="l-filter">
                <div class="l-filter-inner">
                    <div class="headline headline-min">
                        <h2>タイトル</h2>
                    </div>
                    <div class="l-filter-internal_link" v-if="!isLoading">
                      <InternalLink v-for="service in sorted_services" :service="service" :key="service.id"></InternalLink>
                    </div>
                    <Loading :is_show="isLoading" sub="min"></Loading>
                </div>
            </div>
        </div>
    </div>
    `,
    computed:{
        ...mapGetters('mainParams',{services:'getServices'}),
        sorted_services(){
            // コンテンツを名前順でソートしたい
            return this.services.sort((a,b)=> new Intl.Collator('ja').compare(a.name,b.name))
        },
        isLoading(){
           return  !this.services || !this.services.length;
        }
    },
    components:{
        Loading,
        InternalLink:{
            name:'InternalLink',
            props:{
                service:{
                    type:Object,
                    required: true
                }
            },
            template:`
                <p class="internal_link">
                    <a v-bind:href="url">{{ service.label }}</a>
                </p>
            `,
            computed:{
                ...mapGetters('urls',['getUrlOf']),
                url(){
                    return this.getUrlOf({key:'SERVICE_ITEMS',params:{service_id:this.service.value}});
                }
            }
        }
    },
};
