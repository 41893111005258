export const Loading = {
    name: "Loading",
    template:`
     <transition name="fade">
        <div v-if="is_show" class="loading" :class="class_name">
            <p>Loading...</p>
        </div>
    </transition>
    `,
    props:{
        is_fixed:{
            type:Boolean,
            default(){
                return false;
            }
        },
        is_show:{
            type:Boolean,
            default(){
                return false;
            }
        },
        sub:{
            type:String,
            default(){
                return '';
            }
        }
    },
    computed:{
        class_name(){
            let _ret = {
                'is-fixed':this.is_fixed
            };
            if(this.sub){
                _ret['loading-'+ this.sub] = true;
            }
            return _ret;
        }
    },
    mounted() {
        if(this.is_show && this.is_fixed){
            window.wrapperController.fixed();
        } else {
            window.wrapperController.cancelFixed();
        }
    },
    watch:{
        is_show(new_val){
            if(new_val &&  this.is_fixed){
                window.wrapperController.fixed();
            } else {
                window.wrapperController.cancelFixed();
            }
        }
    }
};
